<div class="col-12" *ngIf="!loading">
    <div id="carouselExampleIndicators" class="carousel slide">
        <div class="carousel-indicators">
            <button *ngFor="let slider of sliders; let i = index" type="button"
                data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="i" [ngClass]="{'active': i === 0}"
                [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="i"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item" [ngClass]="{'active': i === 0}" *ngFor="let slider of sliders; let i = index">
                <img src="{{ slider.thumbnail }}" class="d-block w-100 custom-img-carousel" alt="{{ slider.title }}">
                <div class="carousel-caption d-flex align-items-center">
                    <div>
                        <h1 class="text-white">{{ slider.title }}</h1>
                        <h3 *ngIf="slider.sub_title" style="color:#01adee;">{{ slider.sub_title }}</h3>
                        <div class="slider-description" [innerHTML]="slider.description"></div>
                        <a class="default-btn" type="button"
                            *ngIf="currentUser.isEmpty && slider.show_button_mode_desconnect"
                            href="{{ slider.link_button_mode_desconnect }}">{{ slider.text_button_mode_desconnect }}</a>
                        <a class="default-btn" type="button"
                            *ngIf="!currentUser.isEmpty && slider.show_button_mode_connect"
                            href="{{ slider.link_button_mode_connect }}">{{ slider.text_button_mode_connect }}</a>
                    </div>
                </div>
            </div>
        </div>
        <a href="#" onclick="javascript:void(0)" class="carousel-control-prev"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </a>
        <a href="#" onclick="javascript:void(0)" class="carousel-control-next"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </a>
    </div>
</div>

<app-shimmer type="banner" *ngIf="loading"></app-shimmer>