import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login-page.component';
import { LogoutGuard } from 'src/app/_helpers/logout.guard';

const routes: Routes = [
  {
    path: '', component: LoginPageComponent, canActivate: [LogoutGuard],
    data: {
        title: "Connexion"
    }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginPageRoutingModule { }
