import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './reset-password.component';
import { LogoutGuard } from 'src/app/_helpers/logout.guard';

const routes: Routes = [
  {
    path: '', component: ResetPasswordComponent, canActivate: [LogoutGuard],
    data: {
        title: "Réinitialisation de mot de passe"
    }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule { }
