import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  entity: string = "/subscriptions";

  constructor(private http: HttpClient, private authService: AuthService) { }

  get(filters?: string): Observable<any> {
    return this.http.get(environment.api + this.entity + ((filters) ? filters : ""), { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
  }
  
}
