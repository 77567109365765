import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MesAbonnementsRoutingModule } from './mes-abonnements-routing.module';
import { MesAbonnementsComponent } from './mes-abonnements.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';


@NgModule({
  declarations: [
    MesAbonnementsComponent
  ],
  imports: [
    CommonModule,
    MesAbonnementsRoutingModule,
    NgxShimmerLoadingModule
  ]
})
export class MesAbonnementsModule { }
