import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesCategoryPageRoutingModule } from './courses-category-page-routing.module';
import { CoursesCategoryPageComponent } from './courses-category-page.component';
import { CourseCardModule } from '../../common/course-card/course-card.module';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    CoursesCategoryPageComponent
  ],
  imports: [
    CommonModule,
    CoursesCategoryPageRoutingModule,
    CourseCardModule,
    ShimmerModule,
    SharedModule
  ]
})
export class CoursesCategoryPageModule { }
