import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Formation } from 'src/app/models/formation-model';
import { SeoService } from 'src/app/seo/seo.service';
import { FormationService } from 'src/app/services/formation/formation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mon-apprentissage',
  templateUrl: './mon-apprentissage.component.html',
  styleUrls: ['./mon-apprentissage.component.scss'],
})
export class MonApprentissageComponent implements OnInit {
  nbr_items_in_page: number = 12;
  current_page: number = 1;
  load_more: boolean = true;

  formations: Array<Formation> = [];
  formations_complete: Array<Formation> = [];
  formations_not_complete: Array<Formation> = [];
  loading = true;

  constructor(
    private formationService: FormationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'Mes apprentissage',
      url: environment.url + 'mon-apprentissage',
      description:
        "Le nid it est un marché d'apprentissage et d'enseignement en ligne.",
      image: 'https://dev.lenidit.com/assets/images/courses-video-img-2.png',
    });

    this.get_my_learnings();
  }

  get_my_learnings() {
    this.spinner.show();
    this.formationService
      .apprentissage(this.nbr_items_in_page, this.current_page)
      .subscribe(
        (res: any) => {
          this.load_more = (res.data.length == this.nbr_items_in_page) ? true : false;
          
          res.data.forEach((element: any) => {
            let formation = new Formation(Formation.attributeToJson(element));
            if (formation.pourcentage == 100) {
              this.formations_complete.push(formation);
            } else {
              this.formations_not_complete.push(formation);
            }
            this.formations.push(formation);
          });
        },
        (err) => {
          this.formations = [];
          this.formations_complete = [];
          this.formations_not_complete = [];
        }
      )
      .add(() => {
        this.spinner.hide();
        this.loading = false;
      });
  }

  get_more() {
    this.current_page++;
    this.get_my_learnings();
  }

}
