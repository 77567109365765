import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqPageRoutingModule } from './faq-page-routing.module';
import { FaqPageComponent } from './faq-page.component';

import { AccordionModule } from "ngx-accordion";
import { FaqComponent } from '../../common/faq/faq.component';


@NgModule({
  declarations: [
    FaqPageComponent,
    FaqComponent
  ],
  imports: [
    CommonModule,
    FaqPageRoutingModule,
    AccordionModule
  ]
})
export class FaqPageModule { }
