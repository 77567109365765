import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Component({
  selector: 'app-pricings-success',
  templateUrl: './pricings-success.component.html',
  styleUrls: ['./pricings-success.component.scss']
})
export class PricingsSuccessComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  mes_abonnements() {
    this.router.navigate(['/mes-abonnements']);
  }
}
