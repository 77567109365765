import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pricing } from 'src/app/models/pricing-model';
import { Slider } from 'src/app/models/slider-model';
import { Subscription } from 'src/app/models/subscription-model';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SliderService } from 'src/app/services/slider/slider.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  loading_subscriptipn: boolean = true;
  currentUser: User = new User({});
  subscriptions: Array<Subscription> = [];
  access_to_course: boolean = false;
  pricing_selected: Pricing = new Pricing();

  show_cancel_msg: boolean = false;

  gift_sliders: Array<Slider> = [];
  loading_slider: boolean = true;

  constructor(private sliderService: SliderService, private router: Router, private activatedRoute: ActivatedRoute, private subscriptionService: SubscriptionService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = new User(user);
      this.getSubscriptions();
      this.getGiftSlider();
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.show_cancel_msg = (params.subscription && params.subscription == "cancel") ? true : false;
      if (this.show_cancel_msg) {
        Swal.fire('Érreur', "Votre abonnement a échoué, merci de réessayer", "error");
      }
    });
  }

  getGiftSlider() {
    this.sliderService.get("&filters[is_gift][$eq]=true").subscribe((res) => {
      this.gift_sliders = [];
      res.data.forEach((item: any) => {
        let slider = new Slider(Slider.attributeToJson(item));
        if (slider.left_gift > 0) {
          this.gift_sliders.push(slider);
        }
      });

    }).add(() => {
      this.loading_slider = false;
    });
  }

  getSubscriptions() {
    if (!this.currentUser.isEmpty) {
      this.subscriptionService.get("?filters[active][$eq]=true&populate=*").subscribe((res) => {
        this.subscriptions = [];
        res.data.forEach((item: any) => {
          this.subscriptions.push(new Subscription(Subscription.attributeToJson(item)));
        });
        this.access_to_course = this.subscriptions.filter((element) => element.active == true).length > 0;
        this.pricing_selected = this.subscriptions.find((element) => element.active)?.pricing as Pricing;


      }, err => {
        this.subscriptions = [];
      }).add(() => {
        this.loading_subscriptipn = false;

      });
    } else {
      this.loading_subscriptipn = false;
    }


  }

}


