<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Mentions Légales</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Mentions Légales</li>
            </ul>
        </div>
    </div>
</div>

<!-- Mentions Légales -->
<div class="ptb-100">
    <div class="container">
        <div class="main-content-text">
            <ul>
                <li>Sarl au capital de 2000 Euros</li>
                <li>SIRET : 851 391 169 00014</li>
                <li>8 RUE DES MUNITIONNETTES</li>
                <li>31300 TOULOUSE</li>
                <li>Directeur de la publication :<b> Mr Aimad TAHI</b></li>
            </ul>
            <br>
            <p>Toutes les pages de ce site web sont protégées par le droit d’auteur français et la législation sur la
                protection des bases de données. Aucune reproduction de ce document, même partielle, autres que celles
                prévues à l’article L 122-5 du Code de la propriété intellectuelle, ne peut être faite sans
                l’autorisation expresse de l’auteur.</p>
            <h4>1.RGPD.</h4>
            <p>Dans le cadre du nouveau Règlement Général sur la Protection des données (RGPD) entré en vigueur en
                Europe depuis le 25 mai 2018, Le Nid IT fait ses meilleurs efforts pour assurer la protection, la
                confidentialité et la sécurité des données personnelles qui lui ont été transmises et ce dans le respect
                des dispositions légales applicables.</p>
            <h4>2.Qu’est ce que le Règlement Général sur la Protection des Données ?</h4>
            <p>Selon la définition de la CNIL (Commission nationale de l’informatique et des libertés), le RGPD encadre
                le traitement des données personnelles sur le territoire de l’Union Européenne. Le contexte juridique
                s’adapte pour suivre les évolutions des technologies et des sociétés (usage du numérique, développement
                du commerce en ligne…) Ce nouveau règlement européen s’inscrit dans la continuité de la Loi française «
                Informatique et Libertés » de 1978 et renforce le contrôle par les citoyens de l’utilisation qui peut
                être faite des données les concernant.</p>
            <p>Il harmonise les règles en Europe en offrant un cadre juridique unique aux professionnels. Il permet de
                développer leurs activités numériques au sein de l’Union européenne en se fondant sur la confiance des
                utilisateurs.</p>
            <h4>3.Qu’est ce qu’une donnée à caractèrepersonnel ?</h4>
            <p>Une donnée à caractère personnel est une donnée qui permet d’identifier directement ou indirectement une
                personne: nom, prénom, adresse postale, adresse électronique…</p>
            <h4>4.Quand Le Nid IT collecte-t-elle vos données ?</h4>
            <p>Le Nid IT peut être amenée à collecter vos données personnelles lors de nos échanges de courrier, de
                l’abonnement à la Newsletter ou encore à l’occasion de nos échanges commerciaux.</p>
            <p>Le Nid IT vous assure que l’usage de vos données à caractère personnel est strictement limité à
                l’entreprise. Aucun usage commercial extérieur n’en sera fait et aucun transfert à des tiers externes ne
                sera effectué.</p>
            <h4>5.Qui est le responsable de traitement?</h4>
            <p>La collecte est effectuée par les équipes administratives, commerciales et techniques de Le Nid IT.</p>
            <h4>6.Quelles sont les finalités du traitement ?</h4>
            <p>Un « traitement de données personnelles » est une opération portant sur des données personnelles, quel
                que soit le procédé utilisé (collecte, enregistrement, organisation, conservation, consultation,
                utilisation, etc).</p>
            <h4>7.Le Nid IT gère vos données personnelles pour différentes raisons :</h4>
            <ul>
                <li>La réalisation de devis, bons de commande, ventes, …</li>
                <li>L’inscription à la newsletter </li>
                <li>Les échanges de courriers et courriels habituels</li>
            </ul>
            <p>Les données à caractère personnel sont conservées durant la durée des relations avec vous. Pour plus
                d’informations, contactez nous.</p>
            <h4>Quels sont les destinataires des données à caractère personnel ? </h4>
            <p>Les données à caractère personnel collectées par Le Nid IT sont destinées à son usage propre.</p>
            <p>A aucun moment Le Nid IT ne cédera ou ne partagera vos données sans accord de votre part.</p>
            <h4>Quels sont vos droits ?</h4>
            <p>Conformément à la réglementation en vigueur relative aux données à caractère personnel, vous disposez
                d’un droit d’accès, de rectification, de limitation du traitement des données, d’opposition et
                d’effacement des données vous concernant. Si vous vous opposez à la conservation de vos adresses dans
                nos fichiers, nous vous demandons de nous le signifier par mail à l’adresse suivante :
                <a href="a.tahi@lenidit.com">a.tahi@lenidit.com</a>
                Une réponse vous sera alors adressée dans un délai d’un mois suivant la réception de cette demande.
            </p>
            <p>Nous vous remercions pour votre confiance.</p>
        </div>
    </div>
</div>