import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Formation } from 'src/app/models/formation-model';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { CertificateService } from 'src/app/services/certificate/certificate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-suggestion-course-card',
  templateUrl: './suggestion-course-card.component.html',
  styleUrls: ['./suggestion-course-card.component.scss']
})
export class SuggestionCourseCardComponent implements OnInit {

  @Input() formation!: Formation;
  @Input() loading!: boolean;
  @Input() show_details: boolean = true;


  note = 0;
  defaultImage: string = 'assets/images/default-image.webp';

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private certificateService: CertificateService,
    public router: Router,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    // calculate reviews note
    if (this.formation.reviews && this.formation.reviews.length > 0) {
      this.note =
        this.formation.reviews.reduce((total, review: any) => {
          total += review.note;
          return total;
        }, 0) / this.formation.reviews.length;
    }
  }

  generate_certificat() {
    let currentUser = new User({});
    this.authService.currentUserSubject.subscribe((user) => {
      currentUser = new User(user);
    });

    if (!currentUser.isEmpty) {
      if (this.formation.certificate == '') {
        this.spinner.show();
        this.certificateService
          .create({ formation: this.formation.id })
          .subscribe(
            (res: any) => {
              const url = this.router.createUrlTree([
                '/attestation/' + res.token,
              ]);
              window.open(url.toString(), '_blank');
            },
            (err) => {
              Swal.fire('Erreur', "Une erreur s'est produite", 'error');
            }
          )
          .add(() => {
            this.spinner.hide();
          });
      } else {
        const url = this.router.createUrlTree([
          '/attestation/' + this.formation.certificate,
        ]);
        window.open(url.toString(), '_blank');
      }
    }
  }

  addToCart(formationId: string) {
    this.cartService.addToCart(formationId);
    this.openModal();
  }

  openModal() {
    const priceToDisplay = this.formation.has_promo
  ? `<span style="font-size: 18px !important; font-weight: bold; color: rgb(190, 30, 30); text-decoration: line-through !important;">${this.formation.price}€</span>` + (this.formation.new_price > 0 ? `<h2>${this.formation.new_price}€</h2>` : '<h2> Gratuit</h2>')
  : (this.formation.price === 0 ? `<h2> Gratuit</h2>` : `<h2>${this.formation.price}€</h2>`);
  
    Swal.fire({
      title: "Formation ajoutée avec succès",
      icon: "success",
      html: `
      <div class="d-lg-flex mt-2">
          <img style="margin-bottom: 15px;" width="200" alt="image" 
              src="${this.formation.thumbnail}" class="img">
          <div style="padding-left: 0.5rem !important;text-align: left !important;"><strong class="text-gray-dark">${this.formation.title}</strong>
              <div style="display: flex !important;" >
              ${priceToDisplay}
              </div>
              <ul class="p-0 list" style="marging-top: 0.5rem !important;marging-bottom: 0.5rem !important;">
                  <li style="list-style: none !important;">
                      <div class="d-flex pb-2"><i class="ri-group-fill pe-2 text-info"></i> Accès illimité </div>
                  </li>
                  <li style="list-style: none !important;">
                      <div class="d-flex pb-2 text-black-100"><i class="ri-file-text-line pe-2 text-info"></i> Attestation de
                          formation </div>
                  </li>
                  <li style="list-style: none !important;">
                      <div class="d-flex pb-2 text-black-50"><i class="ri-file-download-fill pe-2 text-info"></i> Ressources
                          de formation </div>
                  </li>
              </ul>
          </div>
      </div>
      `,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: `
      Voir mon panier
      `,
      cancelButtonText: `
        Continuer
      `,
    }).then(res => {
      if (res.isConfirmed) {
        setTimeout(() => {
          this.router.navigate(['/cart']).then(() => {
            window.scrollTo(0, 0);
          });
        }, 500)
      }
    })
  }

  checkIfInCart(formation: string): boolean {
    return this.cartService.checkIfExist(formation);
  }

}
