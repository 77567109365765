import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { Observable } from 'rxjs';
import { AuthAdminService } from '../services/auth-admin/auth-admin.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authAdminService: AuthAdminService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin();
  }

  private async checkLogin(): Promise<boolean | UrlTree> {
    try {
      let admin_is_connected = await this.authAdminService.isAuthenticated();
      if (admin_is_connected) {
        try {
          let user_is_connected = await this.authService.isAuthenticated();
          if (user_is_connected) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        } catch (errorUser) {
          this.router.navigate(['/login']);
          return false;
        }
      } else {
        this.router.navigate(['/coming-soon']);
        return false;
      }
    } catch (errorAdmin) {
      this.router.navigate(['/coming-soon']);
      return false;
    }
  }

  /*
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {


      let admin_is_connected = await this.authAdminService.isAuthenticated();

      this.authAdminService.currentUserSubject.subscribe(
        (admin) => {
          let admin_is_logged = admin != null;
          if (admin_is_logged) {
            this.authService.currentUserSubject.subscribe(
              (user) => {
                let user_is_logged = user != null;
                if (user_is_logged) {
                  resolve(true); // User is authenticated
                } else {
                  // Not logged in, redirect to login page
                  this.router.navigate(['/login'], {
                    queryParams: { return: state.url },
                  });
                  resolve(false);
                }
              },
              (errUser) => {
                // Error occurred, redirect to login page
                this.router.navigate(['/login'], {
                  queryParams: { return: state.url },
                });
                resolve(false);
              }
            );
          } else {
            this.router.navigate(['/coming-soon'], {
              queryParams: { return: state.url },
            });
            resolve(false);
          }

        },
        (errAdmin) => {
          this.router.navigate(['/coming-soon']);
          resolve(false);
        }
      );
      

      
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.currentUserSubject.subscribe(
        (user) => {
          let is_logged = user != null;
          if (is_logged) {
            resolve(true); // User is authenticated
          } else {
            // Not logged in, redirect to login page
            this.router.navigate(['/login'], {
              queryParams: { return: state.url },
            });
            resolve(false);
          }
        },
        (err) => {
          // Error occurred, redirect to login page
          this.router.navigate(['/login'], {
            queryParams: { return: state.url },
          });
          resolve(false);
        }
      );
    });
  }
  */
}
