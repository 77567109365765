import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  FormResetPassword: FormGroup;
  loading = false;
  submitForgotPassword = false;
  code = "";
  path = "";
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthService, private userService: UserService, private activatedRoute: ActivatedRoute
  ) {
    this.FormResetPassword = this.formBuilder.group({
      code: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: this.checkPasswords })
  }
  // convenience getter for easy access to form fields
  get resetPassword() { return this.FormResetPassword.controls; }

  ngOnInit(): void {
  }

  checkPasswords(group: any) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  onSubmit(): void {

    this.activatedRoute.queryParams.subscribe(params => {

      this.code = params.code;
      this.submitForgotPassword = true;
      if (this.FormResetPassword.invalid) {
        if (this.FormResetPassword.invalid) {
          if (this.FormResetPassword.controls['password'].errors?.required) {
            Swal.fire('Error', 'Le champ "Mot de passe" est requis', 'error');
          } else if (this.FormResetPassword.controls['password'].errors?.minlength) {
            Swal.fire('Error', 'Le mot de passe doit contenir au moins 6 caractères', 'error');
          } else if (this.FormResetPassword.controls['confirmPassword'].errors?.required) {
            Swal.fire('Error', 'Le champ "Confirmez votre mot de passe" est requis', 'error');
          } else if (
            this.FormResetPassword.value.password !== this.FormResetPassword.value.confirmPassword
          ) {
            Swal.fire('Error', 'Les mots de passe ne correspondent pas', 'error');
          } else {
            Swal.fire('Error', 'Veuillez vérifier vos informations', 'error');
          }
          return;
        }
      }
      this.spinner.show();
      this.authenticationService.resetPassword(this.code, this.FormResetPassword.value.password, this.FormResetPassword.value.confirmPassword).subscribe(data => {
        Swal.fire('Félicitation', 'Votre mot de passe a été changé avec succès', 'success');
        this.router.navigate(['/login']); this.router.navigate(['/login']);
      }, err => {
        if (Array.isArray(err.error.message)) {
          if (err.error.message[0].messages[0].id == "Auth.form.error.code.provide") {
            Swal.fire('Erreur', 'Lien expiré veuillez tester à nouveau', 'error');
          } else {
            Swal.fire('Erreur', 'Une érreur est survenue', 'error');
          }
        } else {
          Swal.fire('Erreur', 'Une érreur est survenue', 'error');
        }

      }).add(() => {
        this.spinner.hide();
      });
    });

  }

}
