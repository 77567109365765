import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MesFacturesRoutingModule } from './mes-factures-routing.module';
import { MesFacturesComponent } from './mes-factures.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';

@NgModule({
  declarations: [
    MesFacturesComponent
  ],
  imports: [
    CommonModule,
    MesFacturesRoutingModule,
    NgxShimmerLoadingModule
  ]
})
export class MesFacturesModule { }
