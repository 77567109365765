import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from 'src/app/models/profile-model';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { CustomDatepickerI18n, I18n } from 'src/app/services/translation/I18n.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mon-profil',
  templateUrl: './mon-profil.component.html',
  styleUrls: ['./mon-profil.component.scss']
})
export class UserProfilComponent implements OnInit {
  file!: File;
  image: any;
  currentUser!: User;
  form!: FormGroup;
  resetPassForm!: FormGroup;
  submitted = false;
  submittedResetPassword = false;
  startDate: any;
  endDate: any;
  show_profile_form: string = "";

  constructor(private ngbCalendar: NgbCalendar, private activatedRoute: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private profileService: ProfileService, private userService: UserService, private authService: AuthService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      address: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      short_address: ['', Validators.required],
      postal_code: ['', Validators.required],
      title: ['monsieur', Validators.required],
      date_of_birthday: ['', Validators.required],
      web_site: [''],
      email: ['', Validators.required],
      speciality: [''],
      description: [''],
      //twitter: [''],
      //youtube: [''],
      //linkedin: [''],
      //facebook: ['']
    })
    this.resetPassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  get formControls() {
    return this.form.controls;
  }

  get resetPassFormControls() {
    return this.resetPassForm.controls;
  }

  ngOnInit(): void {
    // date de naissance
    this.startDate = this.beforeDate(300);
    this.endDate = this.afterDate(0);

    this.activatedRoute.queryParams.subscribe(params => {
      this.show_profile_form = (params.form) ? params.form : "";
    });

    this.activatedRoute.data.subscribe(({ user }) => {
      
      this.currentUser = new User(user);
      this.image = this.currentUser.profile.thumbnail;
      this.currentUser.profile.email = this.currentUser.email;
      this.currentUser.profile.title = (this.currentUser.profile.title == "" || this.currentUser.profile.title == null) ? "monsieur" : this.currentUser.profile.title;

      this.form.patchValue(this.currentUser.profile);
    }, err => {
      console.log(err);
    })
  }

  beforeDate(year: any) {
    return this.ngbCalendar.getPrev(this.ngbCalendar.getToday(), 'y', year);
  }

  afterDate(year: any) {
    const maxDate = this.ngbCalendar.getPrev(this.ngbCalendar.getToday(), 'y', year);
    maxDate.month = 12;
    maxDate.day = 31;
    return maxDate;
  }

  uploadFile(event: any) {
    var reader = new FileReader();
    this.file = event.target.files[0];
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width < 200 || img.height < 200) {
        Swal.fire('Érreur', "L'image téléchargée est trop petite. La taille d'image minimale est 200x200px. Veuillez télécharger une image plus grande", "error");
        return
      } else {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.image = reader.result;
        }
      }
    }
  }

  calculateAge(birthdate: string): number {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust the age if the birthdate hasn't occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      return yearsDiff - 1;
    }

    return yearsDiff;
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }

    /*
    if (!this.form.value.short_address || !this.form.value.country || !this.form.value.city || !this.form.value.postal_code) {
      Swal.fire('Erreur', "Veuillez vérifier l'adresse", 'error');
      return;
    }
    if (!this.form.value.address.includes(this.form.value.short_address)) {
      Swal.fire('Erreur', "Veuillez vérifier l'adresse", 'error');
      return;
    }
    */

    this.form.value["address"] = this.form.value["short_address"];

    const age = this.calculateAge(this.form.value["date_of_birthday"]);
    if (age < 15) {
      Swal.fire('Attention', "Vous devez avoir au moins 15 ans.", 'info');
      return;
    }

    this.spinner.show();
    this.form.value["email"] = this.currentUser.email;
    this.profileService.update(this.currentUser.profile.id, this.form.value, this.file, this.authService.currentUserValue.token).subscribe(res => {
      // set new data in localstorage
      let userJson: any = User.toJson(this.currentUser, this.authService.currentUserValue.token);
      userJson.profile = res.data.attributes;
      userJson.profile.thumbnail = this.image;

      localStorage.setItem('currentUser', JSON.stringify(userJson));
      this.authService.callCurrentUserSubject(userJson);
      this.redirectAfterLogin();
      Swal.fire('Félicitation', 'Profil modifié avec succès', 'success');
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(() => {
      this.spinner.hide();
    })
  }

  checkPasswords(group: any) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('passwordConfirmation').value;
    return password === confirmPassword ? null : { notSame: true }
  }

  resetPassword() {
    this.submittedResetPassword = true;
    if (this.resetPassForm.invalid) {
      if (this.resetPassForm.controls['password'].errors?.required) {
        Swal.fire('Error', 'Le champ "Mot de passe" est requis', 'error');
      } else if (this.resetPassForm.controls['password'].errors?.minlength) {
        Swal.fire('Error', 'Le mot de passe doit contenir au moins 6 caractères', 'error');
      } else if (this.resetPassForm.controls['passwordConfirmation'].errors?.required) {
        Swal.fire('Error', 'Le champ "Confirmez votre mot de passe" est requis', 'error');
      } else if (
        this.resetPassForm.value.password !== this.resetPassForm.value.passwordConfirmation
      ) {
        Swal.fire('Error', 'Les mots de passe ne correspondent pas', 'error');
      } else {
        Swal.fire('Error', 'Veuillez vérifier vos informations', 'error');
      }
      return;
    }
    let ob: any = this.checkPasswords(this.resetPassForm);
    if (ob && ob['notSame'] == true) {
      Swal.fire('Erreur', 'les mots de passe ne sont pas identiques', 'error');
      return;
    }
    this.spinner.show();
    this.userService.change_password(this.resetPassForm.value, this.authService.currentUserValue.token).subscribe(res => {
      Swal.fire('Félicitation', 'Mot de pass modifié avec succès', 'success');
      this.resetPassForm.reset();
      this.submittedResetPassword = false;
      if (this.show_profile_form == 'reset-password') {
        this.router.navigate(['/']);
      }
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(() => {
      this.spinner.hide();
    })
  }

  /*
  public handleAddressChange(place: Address) {
    let city: string = "";
    let country: string = "";
    let short_address: string = "";
    let postal_code = "";

    place.address_components.filter((component: any) => {
      switch (component.types[0]) {
        case "locality":
          city = component.long_name;
          return true;
        case "postal_code":
          postal_code = component.long_name;
          return true;
        case "street_number":
          short_address = short_address + component.long_name;
          return true;
        case "route":
          short_address = short_address + " " + component.long_name;
          return true;
        case "country":
          country = component.long_name;;
          return true;
        default:
          return false;
      }
    });
    this.form.controls.address.setValue(place.formatted_address);
    this.form.controls.city.setValue(city);
    this.form.controls.country.setValue(country);
    this.form.controls.short_address.setValue(short_address);
    this.form.controls.postal_code.setValue(postal_code);
  }
  */

  redirectAfterLogin() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.return != null) {
        this.router.navigate(['/' + params.return]);
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
