<div class="popular-courses-area pb-2" *ngIf="formations.length > 0">
    <div class="container">
        <div class="section-title left-title d-flex justify-content-between align-items-center">
            <h2>Nos Formations à venir</h2>
            <span class="default-btn" *ngIf="loading && formations.length > 4">Voir tout</span>
            <a routerLink="/upcoming-courses" class="default-btn" *ngIf="!loading && formations.length > 4">Voir tout</a>
        </div>
        <div class="row" *ngIf="!loading">
            <div class="col-xl-3 col-sm-6" *ngFor="let formation of (!loading ? formations : 4 | arraySize)">
                <app-course-card [formation]="formation" [loading]="loading"
                    [show_details]="(formation.certificate != '' || formation.pourcentage == 100) ? false : true"></app-course-card>
            </div>
            <p *ngIf="formations.length == 0" style="text-align: center;">Aucun résultat trouvé</p>
        </div>
        <app-shimmer [size]="8" type="col-course-3" *ngIf="loading"></app-shimmer>
    </div>
</div>