import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonApprentissageRoutingModule } from './mon-apprentissage-routing.module';
import { MonApprentissageComponent } from './mon-apprentissage.component';
import { TabsModule } from 'ngx-tabset';
import { CourseCardModule } from '../../common/course-card/course-card.module';


@NgModule({
  declarations: [
    MonApprentissageComponent
  ],
  imports: [
    CommonModule,
    MonApprentissageRoutingModule,
    TabsModule,
    CourseCardModule
  ]
})
export class MonApprentissageModule { }
