import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesDetailsPageComponent } from './courses-details-page.component';
import { AdminAuthGuard } from 'src/app/_helpers/admin-auth.guard';

const routes: Routes = [
  { path: '' , component: CoursesDetailsPageComponent, canActivate: [AdminAuthGuard]},
  { path: ':chapter' , component: CoursesDetailsPageComponent, canActivate: [AdminAuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesDetailsPageRoutingModule { }
