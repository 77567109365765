import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchPageComponent } from './search-page.component';
import { AdminAuthGuard } from 'src/app/_helpers/admin-auth.guard';

const routes: Routes = [
  {
    path: '', component: SearchPageComponent,

    data: {
        title: "Recherche"
    }, canActivate: [AdminAuthGuard]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchPageRoutingModule { }
