export class PaymentMethod {
    id!: string;
    brand!: string;
    exp_month!: string;
    exp_year!: string;
    last4!: string;

    constructor(data: Partial<PaymentMethod>) {
        Object.assign(this, data);
    }

    static attributeToJson(item: any) {
        let obj = item.card;
        obj["id"]= item.id;
        return obj;
    }
    
}