import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscribeEmailService } from 'src/app/services/subscribe-email/subscribe-email.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private subscribeEmail: SubscribeEmailService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {

  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      Swal.fire('Erreur', 'Veuillez entrer une adresse email valide', 'error');
      return;
    }

    this.spinner.show();
    this.subscribeEmail.create(this.form.value).subscribe(res => {
      Swal.fire('Envoyé', 'Merci de vous être inscrit à notre newsletter ! Découvrez bientôt nos actualités et offres exclusives dans votre boîte de réception.', 'success');
      this.form.reset();
      this.submitted = false;
    }, err => {
      if (err.error && err.error.error.message == "This attribute must be unique"){
        Swal.fire('Attention', 'Votre adresse email est déjà enregistrée, vous allez recevoir nos actualités et offres exclusives dans votre boîte de réception.', 'info');
      }else{
        Swal.fire('Erreur', 'Une érreur est survenue', 'error');
      }
    }).add(() => {
      this.spinner.hide();
    });
  }
}