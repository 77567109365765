import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesPageComponent } from './courses-page.component';
import { AdminAuthGuard } from 'src/app/_helpers/admin-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: CoursesPageComponent,
    data: {
      title: "Nos formations",
      description: "Le nid it est un marché d'apprentissage et d'enseignement en ligne.",
      image: "https://dev.lenidit.com/assets/images/courses-video-img-2.png"
    }, canActivate: [AdminAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesPageRoutingModule { }
