import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Slider } from 'src/app/models/slider-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  entity: string = "/sliders";

  constructor(private http: HttpClient) { }

  get(custom_filters?: String): Observable<any> {
    return this.http.get(environment.api + this.entity + "?populate=image&filters[active][$eq]=true"+((custom_filters != null && custom_filters != undefined) ? custom_filters : ""), httpOptions);
  }
}
