import { Validators } from "@angular/forms";
import { Chapter } from "./chapter-model";
import { DateFormatterService } from "../services/date-formatter/date-formatter.service";

export class Learning {
    id: number =0;
    chapter: Chapter = new Chapter();
    duration: number = 0;
    current_time?: string;
    type_video: string = "";
    is_completed: boolean = false;

    constructor(data?: Partial<Learning>) {
        if (data) {
            Object.assign(this, data);
            this.duration = (data.duration) ? data.duration : 0;
            this.current_time = DateFormatterService.toHoursAndMinutes((data.duration) ? 0 : data.duration);
            this.is_completed = (Number(this.duration.toFixed(0)) == Number(this.chapter?.duration.toString()));
        }
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;

        let objChapter = item.attributes.chapter.data.attributes;
        objChapter["id"] = item.attributes.chapter.data.id;
        obj["chapter"] = objChapter;

        return obj;
    }
}
