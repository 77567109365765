import { Injectable } from '@angular/core';
import { Review } from 'src/app/models/review-model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  entity: string = "/reviews";

  constructor(private http: HttpClient, private authService:AuthService) { }

  create(review: any): Observable<any> {
    return this.http.post(environment.api + this.entity + '?populate=*', {
      "data": review
    }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } }
    );
  }

  check(formation_id: string){
    return this.http.get(environment.api + this.entity +"/check/"+formation_id , { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
  }

  get_home_page() : Observable<any> {
    return this.http.get(environment.api + this.entity+"?filters[show_in_home]=true&populate=formation,student.avatar,photo" , httpOptions);
  }

}
