import { Injectable } from '@angular/core';
import { Profile } from 'src/app/models/profile-model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  entity: string = "/profiles";

  constructor(private http: HttpClient) { }

  create(profile: Profile, jwt: string): Observable<any> {
    return this.http.post(environment.api + this.entity + '?populate=*', {
      "data": profile
    }, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } }
    );
  }

  update(profileId: any, data: any, avatar: File, token: any): Observable<any> {
    const formData = new FormData();
    if (avatar != null) {
      formData.append("files.avatar", avatar);
    }
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.entity + "/" + profileId, formData,
      {
        headers: { Authorization: 'Bearer ' + token }
      });
  }


}
