import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact } from 'src/app/models/contact-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  entity: string = "/contacts";

  constructor(private http: HttpClient) { }

  create(contact: Contact): Observable<any> {
    return this.http.post(environment.api + this.entity, {
      "data": contact
    }, httpOptions
    );
  }

}
