import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SwitchSubscriptionRoutingModule } from './switch-subscription-routing.module';
import { SwitchSubscriptionComponent } from './switch-subscription.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SwitchSubscriptionComponent
  ],
  imports: [
    CommonModule,
    SwitchSubscriptionRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SwitchSubscriptionModule { }
