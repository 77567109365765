import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricings-cancel',
  templateUrl: './pricings-cancel.component.html',
  styleUrls: ['./pricings-cancel.component.scss']
})
export class PricingsCancelComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  mes_abonnements() {
    this.router.navigate(['/mes-abonnements']);
  }

}
