<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Nos formations</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Nos formations
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Courses -->
<div class="popular-courses-area ptb-100">
    <div class="container">

        <div class="row">
            <div class="col-md-3 categories">
                <h3>Catégories</h3>
                <ul>
                    <li *ngFor="let category of categories">
                        <a class="my-custom-link" href="/category/{{ category.slug }}">
                            {{ category.name }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <div class="showing-result">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="showing-result-count">
                                <p *ngIf="formations.length > 0">Nous avons trouvé <strong>{{ total_items }}</strong>
                                    cours disponibles pour vous</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!--
<div class="showing-top-bar-ordering">
                                <label>Trier par :</label>
                                <select class="form-select">
                                    <option selected>Default</option>
                                    <option value="1">Popularity</option>
                                    <option value="2">Latest</option>
                                    <option value="3">Price: low to high</option>
                                    <option value="3">Price: high to low</option>
                                </select>
                            </div>
                            -->

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" *ngIf="formations.length == 0 && !loading">
                        <h4 style="text-align: center;">Aucun résultat trouvé</h4>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="row" *ngIf="!loading">
                            <div class="col-xl-4 col-sm-6" *ngFor="let formation of (!loading ? formations : 6 | arraySize)">
                                <app-course-card [formation]="formation" [loading]="loading" [show_details]="(formation.pourcentage == 100) ? false : true"></app-course-card>
                            </div>
                        </div>
                        <app-shimmer [size]="9" type="col-course-4" *ngIf="loading"></app-shimmer>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="formations.length > 0 && page_count > 1">
                        <div class="pagination-area">
                            <a routerLink="/courses" [queryParams]="{page: previous() }" class="prev page-numbers"><i
                                    class="ri-arrow-left-line"></i> Précédent </a>

                            <a routerLink="/courses" class="next page-numbers" [queryParams]="{page: next() }">Suivant
                                <i class="ri-arrow-right-line"></i> </a>
                            <span class="page-numbers current" aria-current="page">{{ current_page +' sur '+
                                page_count}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
