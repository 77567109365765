<div 
    class="partner-area ptb-100" *ngIf="partners.length > 0"
>
    <div class="container">
        <div class="section-title">
            <h2>Ils nous font confiance</h2>
        </div>
        <div class="partner-slider" *ngIf="!loading">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let partner of partners">
                    <div class="partner-item">
                        <a href="{{ partner.website }}" target="_blank"><img src="{{ partner.thumbnail }}" alt="{{ partner.name }}"></a>
                    </div>
                </ng-template>
                
            </owl-carousel-o>
            <p *ngIf="partners.length == 0" style="text-align: center;">Aucun résultat trouvé</p>
        </div>
        <app-shimmer [size]="6" type="col-2" *ngIf="loading"></app-shimmer>
    </div>
</div>