<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Félicitation</h2>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container my-section">
        <div class="row">
            <div class="col-md-12">
                <p class="text-center w-100">Votre compte a été activé avec succès</p>
            </div>
        </div>
    </div>
</div>
