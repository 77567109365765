import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesPageRoutingModule } from './courses-page-routing.module';
import { CoursesPageComponent } from './courses-page.component';
import { CourseCardModule } from '../../common/course-card/course-card.module';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    CoursesPageComponent,
  ],
  imports: [
    CommonModule,
    CoursesPageRoutingModule,
    CourseCardModule,
    ShimmerModule,
    SharedModule
  ]
})
export class CoursesPageModule { }
