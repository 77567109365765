<!-- Default Features for All Pages -->
<div 
    class="feature-area pt-100 pb-70"
    [ngClass]="{'d-none' : router.url === '/index-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-feature">
                    <img src="assets/images/icon/learning.png" alt="Image">
                    <div class="title">Accélérer l'apprentissage</div>
                    <p>Adopter des techniques d'apprentissage rapide par des experts du monde réel</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-feature">
                    <img src="assets/images/icon/instructor-online.png" alt="Image">
                    <div class="title">Rencontrez des professeurs experts</div>
                    <p>Chaque expert enseigne ce qu'il fait le mieux, avec des directives claires</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-feature">
                    <img src="assets/images/icon/out-line.png" alt="Image">
                    <div class="title">Efficacité maximale</div>
                    <p>L'apprentissage du cours a le potentiel d'atteindre une efficacité maximale</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Features for Only Home Demo 2 -->
<div 
    class="feature-area style-two d-none"
    [ngClass]="{'d-block' : router.url === '/index-2'}"
>
    <div class="container">
        <div class="feature-bg pt-60 pb-30">
            <div class="row justify-content-center align-items-center">
                <div class="col-xl-3 col-md-6">
                    <div class="single-feature color-style">
                        <img src="assets/images/icon/learning-white.png" alt="Image">
                        <h3>Accélérer l'apprentissage</h3>
                        <p>Adopter des techniques d'apprentissage rapide par des experts du monde réel</p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="single-feature color-style">
                        <img src="assets/images/icon/source-white.png" alt="Image">
                        <h3>Rencontrez des professeurs experts</h3>
                        <p>Chaque expert enseigne ce qu'il fait le mieux, avec des directives claires</p>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="single-feature color-style">
                        <img src="assets/images/icon/out-line-white.png" alt="Image">
                        <h3>Efficacité maximale</h3>
                        <p>L'apprentissage du cours a le potentiel d'atteindre une efficacité maximale</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>