import { Component, SimpleChanges, ElementRef, Input, OnDestroy, OnInit, ViewChild, HostListener, Inject, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Formation } from 'src/app/models/formation-model';
import { FormationService } from 'src/app/services/formation/formation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user-model';
import Swal from 'sweetalert2';
import { Category } from 'src/app/models/category-model';
import { Chapter } from 'src/app/models/chapter-model';
import { Module } from 'src/app/models/module-model';
import { DOCUMENT } from '@angular/common';
import { VideoService } from 'src/app/services/video/video.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from 'src/app/seo/seo.service';
import { Review } from 'src/app/models/review-model';
import { ReviewService } from 'src/app/services/review/review.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { Subscription } from 'src/app/models/subscription-model';
import { Pricing } from 'src/app/models/pricing-model';
import { LearningService } from 'src/app/services/learning/learning.service';
import { Learning } from 'src/app/models/learning-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDismissReasons, NgbActiveModal, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { CertificateService } from 'src/app/services/certificate/certificate.service';
import { Question } from 'src/app/models/question-model';
import { OptionQuestion } from 'src/app/models/option-question-model';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
	selector: 'app-courses-details-page',
	templateUrl: './courses-details-page.component.html',
	styleUrls: ['./courses-details-page.component.scss'],
	styles: [
		`i {
			position: relative;
			display: inline-block;
		}
	
		.filled {
			color: #f6b429;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
		}`,
	],
})

export class CoursesDetailsPageComponent implements OnInit {
	@ViewChild('tabSet') tabSet: any;

	@ViewChild('modal', { static: false }) modal: any;
	@ViewChild('playVideo', { static: true }) playVideo?: ElementRef;
	modal_is_open: boolean = false;
	modalReference: any;
	pourcentage_note: Array<any> = [
		{ "note": 1, "pourcentage": 0 },
		{ "note": 2, "pourcentage": 0 },
		{ "note": 3, "pourcentage": 0 },
		{ "note": 4, "pourcentage": 0 },
		{ "note": 5, "pourcentage": 0 }
	];
	videoSrc = "";
	pausePlayer: boolean = false;
	add_comment: boolean = false;
	currentUser: User = new User({});
	FormComment!: FormGroup;
	formation: Formation = new Formation({});
	isLoading: boolean = true;
	related_formation: Array<Formation> = [];
	formation_slug: string = "";
	selected_chapter: Chapter = new Chapter({});
	current_learning: Learning = new Learning();
	shareUrl: string = "";
	submitted = false;
	note = 0;
	all_chapters: Array<Chapter> = [];
	subscriptions: Array<Subscription> = [];
	learnings: Array<Learning> = [];
	access_to_course: boolean = false;
	pricing_selected: Pricing = new Pricing();
	loading_subscriptipn: boolean = true;
	count_chapters: number = 0;
	pourcentage_learning_course: number = 0;
	loading_related_formations: boolean = false;
	relatedCoursesSlides: OwlOptions = {
		items: 1,
		loop: true,
		margin: 30,
		nav: false,
		dots: true,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true
	};
	videoJsConfigObj: any = {
		preload: "metadata",
		controls: true,
		autoplay: true,
		overrideNative: true,
		techOrder: ["html5", "flash"],
		html5: {
			nativeVideoTracks: false,
			nativeAudioTracks: false,
			nativeTextTracks: false,
			hls: {
				withCredentials: false,
				overrideNative: true,
				debug: true
			}
		}
	};

	closeResult = '';
	currentTime: number = 0;
	noteQcm: number = 0;

	textButtonNext: string = "Chapitre suivant";

	videoLink: string = "";
	showHelpPage: boolean = false;

	defaultImage: string = "assets/images/default-image.webp";



	@HostListener('window:click', ['$event'])
	keyEvent(event: KeyboardEvent) {
		let element: HTMLElement = event.target as HTMLElement;
		if (element.id == "popup-player") {
			/*
			this.pausePlayer = true;
			this.selected_chapter.link = "";
			*/
		}
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		let element: HTMLElement = event.target as HTMLElement;
		if (element.id == "popup-player" || element.id == "vjs_video_3_html5_api") {
			this.pausePlayer = true;
			this.selected_chapter.link = "";
		}
	}

	@HostListener('shown.bs.modal') onModalShown() {
		console.log("modal show");

		const content = document.getElementById('chapters-content');
		const scrollToElement = document.getElementById(this.selected_chapter.slug);
		if (scrollToElement && content) {
			content.scrollTop = scrollToElement.offsetTop - 100;
		}
	}

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private subscriptionService: SubscriptionService,
		private formationService: FormationService,
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private seoService: SeoService,
		private reviewService: ReviewService,
		private learningService: LearningService,
		private spinner: NgxSpinnerService,
		private videoService: VideoService, private sanitizer: DomSanitizer,
		private modalService: NgbModal, public activeModal: NgbActiveModal,
		private certificateService: CertificateService,
		private dateFormatterService: DateFormatterService,
		private cartService: CartService) {
	}

	ngOnInit(): void {
		this.FormComment = this.formBuilder.group({
			note: ['5'],
			comment: ['', Validators.required]
		});
		this.getFormation();
	}

	getFormation() {
		this.spinner.show();
		this.activatedRoute.params.subscribe(route => {
			this.authService.currentUserSubject.subscribe(user => {
				this.currentUser = new User(user);
			});
			this.formation_slug = route.slug;
			this.shareUrl = environment.url + 'courses-details/' + this.formation_slug;
			this.formationService.getBySlug(this.formation_slug).subscribe((res: any) => {
				this.formation = new Formation(res);
				// calculate reviews note
				if (this.formation.reviews.length > 0) {
					this.pourcentage_note.forEach((elementNote: any) => {
						elementNote.pourcentage = (100 * this.formation.reviews.filter((itemReview: Review) => { return parseInt(itemReview.note.toString()) == elementNote.note; }).length) / this.formation.reviews.length;
					});

					this.note = this.formation.reviews.reduce((total, review: any) => {
						total += review.note;
						return total;
					}, 0) / this.formation.reviews.length;
				}
				let title: string = this.formation.meta_title_google;
				let desription: string = this.formation.meta_description_google;
				// meta description
				this.seoService.generateTags({
					title: (title != "" && title != null) ? title : "Cour",
					url: this.shareUrl,
					description: (desription != "" && desription != null) ? desription.replace(/<[^>]*>/g, '') : "",
					image: this.formation.thumbnail,
				});


				this.getChapters();
				if (!this.currentUser.isEmpty) {
					this.getLearnings(route.chapter, true);
					this.checkAddComment();
				} else {
					//this.callPopupPlayer(route.chapter);
					this.loading_subscriptipn = false;
				}
				this.getRelatedFormations();

			}, err => {
				this.router.navigate(['/courses-details/' + this.formation_slug + '/error/404']);
			}).add(() => {
				this.isLoading = false;
				this.spinner.hide();
			});
		});
	}

	getChapters() {
		this.all_chapters = [];
		this.formation.modules.forEach((module: Module) => {
			module.chapters.forEach((chapter: Chapter) => {
				this.all_chapters.push(chapter);
			});
		});
	}

	getSubscriptions(chapter_slug: string) {
		this.subscriptionService.get("?filters[active]=true&populate=*").subscribe((res) => {
			this.subscriptions = [];
			res.data.forEach((item: any) => {
				this.subscriptions.push(new Subscription(Subscription.attributeToJson(item)));
			});
			this.access_to_course = this.subscriptions.filter((element) => element.active == true).length > 0;
			this.pricing_selected = this.subscriptions.find((element) => element.active)?.pricing as Pricing;
			this.callPopupPlayer(chapter_slug);
		}, err => {
			this.subscriptions = [];
		}).add(() => {
			this.loading_subscriptipn = false;
		});
	}

	getLearnings(chapter_slug: string, init: boolean) {
		this.spinner.show();
		this.learnings = [];
		this.learningService.get(this.formation.id.toString()).subscribe((res) => {
			res.forEach((item: any) => {
				this.learnings.push(new Learning(item));
			});
			this.checkIsRead();
			if (init) {
				this.getSubscriptions(chapter_slug);
			}
		}, err => {
			this.learnings = [];
		}).add(() => {
			this.spinner.hide();
		});
	}

	checkFormation(chapter_slug: string) {
		this.spinner.show();
		this.activatedRoute.params.subscribe(route => {
			this.authService.currentUserSubject.subscribe(user => {
				this.currentUser = new User(user);
			});
			this.formation_slug = route.slug;
			this.shareUrl = environment.url + 'courses-details/' + this.formation_slug;
			this.formationService.getBySlug(this.formation_slug).subscribe((res: any) => {
				this.formation = new Formation(res);
				if (!this.currentUser.isEmpty) {
					if (this.formation.pourcentage >= 100) {
						Swal.fire('Félicitation', "Vous avez terminé avec succès la formation '" + this.formation.title + "'", 'success');
						//this.router.navigate(['/courses-details/' + this.formation_slug]);
						if (chapter_slug) {
							this.getLearnings(chapter_slug, false);
						}
					} else {
						if (chapter_slug) {
							this.getLearnings(chapter_slug, false);
						}
					}
				} else {
					this.loading_subscriptipn = false;
				}
			}, err => {
				this.router.navigate(['/courses-details/' + this.formation_slug + '/error/404']);
			}).add(() => {
				this.isLoading = false;
				this.spinner.hide();
			});
		});
	}

	/*
	checkFormation() {
		this.spinner.show();
		this.activatedRoute.params.subscribe(route => {
			this.authService.currentUserSubject.subscribe(user => {
				this.currentUser = new User(user);
			});
			this.formation_slug = route.slug;
			this.shareUrl = environment.url + 'courses-details/' + this.formation_slug;
			this.formationService.getBySlug(this.formation_slug).subscribe((res: any) => {
				this.formation = new Formation(res);
				if (!this.currentUser.isEmpty) {
					if (this.formation.pourcentage >= 100) {
						Swal.fire('Félicitation', "Vous avez terminé avec succès la formation '" + this.formation.title + "'", 'success');
						this.router.navigate(['/courses-details/' + this.formation_slug]);
					} else {
						if (route.chapter) {
							this.getLearnings(route.chapter, false);
						}
					}
				} else {
					this.loading_subscriptipn = false;
				}
			}, err => {
				this.router.navigate(['/courses-details/' + this.formation_slug + '/error/404']);
			}).add(() => {
				this.isLoading = false;
				this.spinner.hide();
			});
		});
	}
	*/

	getRelatedFormations() {
		if (this.formation.categories.length > 0) {
			this.loading_related_formations = true;
			// get related formations
			this.formationService.get_light(4, 1, "&filters[is_draft][$eq]=false&filters[categories][id][$eq]=" + this.formation.categories[0].id).subscribe((res: any) => {
				this.related_formation = [];
				res.data.forEach((item: any) => {
					let formation = new Formation(Formation.attributeToJson(item));
					if (formation.id != this.formation.id) {
						this.related_formation.push(formation);
					}
				});
			}, err => {
				this.related_formation = [];
			}).add(() => {
				this.loading_related_formations = false;
			});
		}
	}

	checkIsRead() {
		this.formation.modules.forEach((module: Module) => {
			module.chapters.forEach((chapter: Chapter) => {
				let found = this.learnings.find((learning: Learning) => { return learning.chapter?.id == chapter.id; });
				if (found) {
					chapter.is_read = (found.type_video == "player") ? found.is_completed : true;
				}
			});
		});
	}

	openModal(from: any) {
		if (!this.modal_is_open) {
			console.log("open modal... from : " + from);

			this.modal_is_open = true;
			this.modalReference = this.modalService.open(this.playVideo, { windowClass: 'popup-modal', ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl', backdrop: 'static', keyboard: false });
		}
	}

	closeModal(from_button: boolean) {
		this.modalReference?.close();
		this.pausePlayer = true;
		this.selected_chapter.link = "";
		this.modal_is_open = false;
		if (from_button) {
			this.router.navigate(['/courses-details/' + this.formation_slug]);
		}
	}

	start_course() {
		if (this.formation.is_learning) {

			let active_chapter = false;
			this.all_chapters.forEach((chapter: Chapter) => {
				if (!active_chapter) {
					let item = this.learnings.find((learning: Learning) => { return learning.chapter?.id == chapter.id });
					if (item) {

						if (Number(item.duration.toFixed(0)) != Number(chapter.time.toString()) && item.type_video == "player") {
							active_chapter = true;
							if (this.selected_chapter != null) {
								if (this.selected_chapter.id == chapter.id) {
									this.callPopupPlayer(chapter.slug);
								} else {
									this.callPopupAction(false, chapter);
								}

							} else {
								this.callPopupAction(false, chapter);
							}


						}
					} else {
						active_chapter = true;
						this.callPopupAction(false, chapter);


					}
				}
			});
		} else {
			if (this.formation.modules.length > 0) {
				if (this.formation.modules[0].chapters.length > 0) {
					this.goToChapter(this.formation.modules[0].chapters[0], false);
				} else {
					Swal.fire('Attention', "Aucune vidéo trouvée", 'info');
				}
			} else {
				Swal.fire('Attention', "Aucune vidéo trouvée", 'info');
			}
		}
	}

	goToChapter(chapter: Chapter, from_popup: boolean) {


		if (chapter.slug != null && chapter.slug != "") {
			if (this.selected_chapter.slug != chapter.slug) {
				this.callPopupAction(false, chapter);

			} else {
				if (!from_popup) {
					this.callPopupPlayer(chapter.slug);
				}
			}
		}
		this.pausePlayer = false;
	}

	onUpdateCurrentTime(newTime: any) {
		this.currentTime = newTime;
	}

	saveCurrentTimeLearning(chapter: Chapter) {

		if (this.access_to_course && chapter.type_video != "") {

			let current_learning = this.learnings.find((element) => element.chapter?.slug == chapter.slug);
			if (current_learning) {

				if (this.formation.pourcentage >= 100) {
					//this.openModal("save 100% ..");
				} else {
					if (chapter.type_video == "player") {

						if (Math.floor(this.currentTime) >= Math.floor(current_learning.duration)) {

							this.learningService.update(current_learning.id?.toString(), this.currentTime.toFixed(0)).subscribe((res) => {
								this.current_learning = new Learning(Learning.attributeToJson(res.data));
								if (Number(this.current_learning.duration.toFixed(0)) == Number(this.current_learning.chapter.duration.toString())) {
									this.checkFormation(chapter.slug);
								} else {
									this.getLearnings(chapter.slug, false);
								}
							});
						}
					} else {
						this.openModal("save youtube ...");
					}
				}
			} else {
				this.learningService.create(chapter.id.toString(), this.currentTime.toFixed(0), this.selected_chapter.type_video).subscribe((res) => {
					this.current_learning = new Learning(Learning.attributeToJson(res.data));
					if (chapter.type_video == "player") {

						if (Number(this.current_learning.duration.toFixed(0)) == Number(this.current_learning.chapter.duration.toString())) {

							this.checkFormation(chapter.slug);
						} else {

							this.getLearnings(chapter.slug, false);
						}
					} else {

						this.checkFormation(chapter.slug);
						//this.getLearnings(chapter.slug, false);
						this.openModal("save not learn...");
					}
				}, err => {

				});

			}

		}
	}

	callPopupPlayer(chapter_slug: string) {
		this.videoLink = "";
		if (chapter_slug) {
			this.selected_chapter = new Chapter({});
			this.formation.modules.forEach((item: Module) => {
				item.chapters.forEach((itemChapt: Chapter) => {
					if (itemChapt.slug == chapter_slug) {
						this.spinner.show();
						this.selected_chapter = itemChapt;

						let chapterFounded = this.all_chapters.find((element) => element.id == this.selected_chapter.id);
						if (chapterFounded) {
							const index: number = this.all_chapters.indexOf(chapterFounded);
							//const index: number = this.all_chapters.indexOf(this.selected_chapter);
							if (index !== -1) {
								if (index < this.all_chapters.length - 1) {
									let find_next_chapter = this.all_chapters[index + 1];
									if (find_next_chapter.type == 'Qcm') {
										this.textButtonNext = "Passer au QCM";
									} else {
										this.textButtonNext = "Chapitre suivant";
									}
								}
							}
						}


						this.check_exist_next_chapter();
						if (this.selected_chapter.type == "Qcm") {
							this.openModal("QCM call...");
							//Swal.fire('Coming soon', "QCM : Coming soon", 'info');
							this.spinner.hide();
						} else {
							this.videoService.getByChapterSlug(chapter_slug).subscribe(async (res: any) => {
								if (res.success) {
									if (res.data == "required.subscription") {
										this.router.navigate(['/courses-details/' + this.formation_slug]);
									} else {
										if (res.data.type != "") {
											this.selected_chapter.type_video = res.data.type;

											this.selected_chapter.link = (res.data.type == "youtube") ? (this.sanitizer.bypassSecurityTrustResourceUrl("//www.youtube.com/embed/" + res.data.value + "?rel=0&modestbranding=0&showinfo=0") as string) : res.data.value;
											this.videoLink = (res.data.type == "youtube") ? (this.sanitizer.bypassSecurityTrustResourceUrl("//www.youtube.com/embed/" + res.data.value + "?rel=0&modestbranding=0&showinfo=0") as string) : res.data.value;

											if (this.selected_chapter.type_video == "youtube") {
												this.saveCurrentTimeLearning(this.selected_chapter);

												this.openModal("first call...");
											} else {
												this.current_learning = this.learnings.find((element) => element.chapter?.slug == this.selected_chapter.slug) as Learning;
												this.openModal("first call...");
											}
										}
									}
								} else {
									if (res.error == "video.not.found") {
										Swal.fire('Erreur', "Cette Vidéo n'est pas Disponible", 'error');
									} else {
										this.router.navigate(['/courses-details/' + this.formation_slug]);
									}

								}
							}, err => {
								Swal.fire('Erreur', "Une erreur s'est produite", 'error');
							}).add(() => {
								this.spinner.hide();
							});
						}

					}
				});
			});
		}

	}

	get comment() {
		return this.FormComment.controls;
	}

	checkAddComment() {
		this.reviewService.check(this.formation.id).subscribe((res: any) => {
			this.add_comment = res;
		}, err => {
			this.add_comment = false;
		});
	}

	onSubmitReview() {
		this.submitted = true;
		let review = {
			"student": this.currentUser.profile.id,
			"formation": this.formation.id,
			"note": this.FormComment.value.note,
			"comment": this.FormComment.value.comment
		};
		if (this.FormComment.invalid) {
			Swal.fire('Erreur', 'Veuillez saisir un commentaire', 'error');
			return;
		}
		this.spinner.show();
		this.reviewService.create(review).subscribe(res => {
			Swal.fire('', 'Commentaire ajouté avec succès', 'success');
			this.FormComment.reset();
			this.FormComment.controls.note.setValue("1");
			this.submitted = false;
			this.getFormation();
		}, err => {
			Swal.fire('Erreur', "Une erreur s'est produite", 'error');
		}).add(() => {
			this.spinner.hide();
		});
	}

	generate_certificat() {
		if (!this.currentUser.isEmpty) {

			if (this.formation.certificate == "") {
				this.spinner.show();
				this.certificateService.create({ "formation": this.formation.id }).subscribe((res: any) => {
					const url = this.router.createUrlTree(["/attestation/" + res.token]);
					window.open(url.toString(), '_blank');
				}, err => {
					Swal.fire('Erreur', "Une erreur s'est produite", 'error');
				}).add(() => {
					this.spinner.hide();
				});
			} else {
				const url = this.router.createUrlTree(["/attestation/" + this.formation.certificate]);
				window.open(url.toString(), '_blank');
			}
		}
	}

	saveLearningQcm(chapter: Chapter) {
		if (this.access_to_course) {

			let current_learning = this.learnings.find((element) => element.chapter?.slug == chapter.slug);
			if (!current_learning) {
				this.learningService.create(chapter.id.toString(), 0, "qcm").subscribe((res) => {
					this.current_learning = new Learning(Learning.attributeToJson(res.data));
					//this.getLearnings(chapter.slug, false);
					this.checkFormation(chapter.slug);
				}, err => {

				});
			}
		}
	}

	setBgSelectedChapter(chapter: Chapter) {
		let styles = {
			"background-color": (chapter.id == this.selected_chapter.id) ? "#01adee" : "transparent",
			"color": (chapter.id == this.selected_chapter.id) ? "#ffffff" : "#6a6f73",
			"font-weight": (chapter.id == this.selected_chapter.id) ? "bold" : "normal",
		};
		return styles;
	}

	setBgSelectedChapterDuration(chapter: Chapter) {
		let styles = {
			"background-color": (chapter.id == this.selected_chapter.id) ? "transparent" : "rgb(222, 235, 248)"
		};
		return styles;
	}

	arrayOne(n: number): any[] {
		return Array(n ? n : 0);
	}

	toNumber(value: any) {
		return Number(value);
	}

	scrollToPricing(el: HTMLElement, chapter?: Chapter) {
		if (this.access_to_course && chapter && !chapter.locked) {
			this.callPopupAction(false, chapter);
		} else {
			this.closeModal(false);
			el.scrollIntoView();
		}
	}

	date_format(date: any) {
		return this.dateFormatterService.showDateHour(date)
	}

	setBgButton(optionFormQuestion: OptionQuestion) {
		let styles = {
			"background-color": optionFormQuestion.bg_color,
			"color": optionFormQuestion.text_color
		};
		return styles;
	}

	choice_question(question: Question, option_selected: OptionQuestion) {

		if (question.multi_select) {
			let indiceFound = question.selectedOptionIndices.find((item: any) => { return item == option_selected.index; });
			let questionFound = question.optionsFormQuestion.find((item: OptionQuestion) => { return item.index == option_selected.index; }) as OptionQuestion;

			if (indiceFound != undefined) {
				let index = question.selectedOptionIndices.indexOf(indiceFound);
				question.selectedOptionIndices.splice(index, 1);
				questionFound.text_color = "#000";
				questionFound.bg_color = "#fff";
			} else {
				question.selectedOptionIndices.push(option_selected.index);
				questionFound.text_color = "#fff";
				questionFound.bg_color = "green";
			}
		} else {
			question.selectedOptionIndices = [option_selected.index];
			question.optionsFormQuestion.forEach((item: OptionQuestion) => {
				if (item.index == option_selected.index) {
					item.text_color = "#fff";
					item.bg_color = "green";
				} else {
					item.text_color = "#000";
					item.bg_color = "#fff";
				}
			});
		}

	}

	next_question_qcm() {
		if (this.selected_chapter) {
			const arraySelected = this.selected_chapter.questions[this.selected_chapter.current_question_index].selectedOptionIndices;
			if (arraySelected.length > 0) {
				this.selected_chapter.current_question_index++;
				if ((this.selected_chapter.current_question_index + 1) > this.selected_chapter.questions.length) {
					this.selected_chapter.questions.forEach((question: Question) => {
						const arrayCorrect = question.correctOptionIndices;
						const arraySelected = question.selectedOptionIndices;
						const includesArray = arrayCorrect.every(val => arraySelected.includes(val));
						if (includesArray && (arrayCorrect.length == arraySelected.length)) {
							this.noteQcm++;
						}
					});
					if (this.calcule_pourcentage_qcm() >= 80) {
						this.saveLearningQcm(this.selected_chapter);
					} else {
						Swal.fire("Attention", "Pour qu'un QCM soit considéré comme réussi, la réponse doit être égale ou supérieure à 80 %", 'info');
					}


				}
			} else {
				Swal.fire("Attention", "veuillez choisir une réponse", 'info');
			}

		}
	}

	show_correct_question_qcm() {
		const questionSelected = this.selected_chapter.questions[this.selected_chapter.current_question_index];
		const arrayCorrect = questionSelected.correctOptionIndices;
		const arraySelected = questionSelected.selectedOptionIndices;
		if (arraySelected.length > 0) {


			const includesArray = arrayCorrect.every(val => arraySelected.includes(val));
			if (includesArray && (arrayCorrect.length == arraySelected.length)) {
				Swal.fire('Bravo', "la réponse est correcte", 'success');
			} else {
				questionSelected.optionsFormQuestion.forEach((item: OptionQuestion) => {
					let foundCorrect = questionSelected.correctOptionIndices.find((element: number) => { return element == item.index });
					if (foundCorrect) {
						item.text_color = "#fff";
						item.bg_color = "green";
					} else {
						let foundNotCorrect = questionSelected.selectedOptionIndices.find((element: number) => { return element == item.index });
						if (foundNotCorrect) {
							item.text_color = "#fff";
							item.bg_color = "red";
						} else {
							item.text_color = "#000";
							item.bg_color = "#fff";
						}
					}
				});
				Swal.fire("Tu te trompes", "la réponse n'est pas correcte", 'error');
			}
		} else {
			Swal.fire("Attention", "veuillez choisir une réponse", 'info');
		}

	}

	calcule_pourcentage_qcm() {
		let pourcentage_qcm = (this.noteQcm * 100) / this.selected_chapter.questions.length;
		return Math.round(pourcentage_qcm);
	}

	refresh_qcm() {
		this.noteQcm = 0;
		this.selected_chapter.current_question_index = 0;
		this.selected_chapter.questions.forEach((question: Question) => {
			question.selectedOptionIndices = [];
			question.optionsFormQuestion.forEach((item: OptionQuestion) => {
				item.text_color = "#000";
				item.bg_color = "#fff";
			});
		});
	}

	check_exist_next_chapter() {
		let chapterFounded = this.all_chapters.find((element) => element.id == this.selected_chapter.id);
		if (chapterFounded) {
			const index: number = this.all_chapters.indexOf(chapterFounded);

			if (index !== -1) {
				if (index == this.all_chapters.length - 1) {
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}

	}

	next_chapter(pricing: HTMLElement) {
		this.check_exist_next_chapter();

		let chapterFounded = this.all_chapters.find((element) => element.id == this.selected_chapter.id);
		if (chapterFounded) {
			const index: number = this.all_chapters.indexOf(chapterFounded);
			if (index !== -1) {
				if (index == this.all_chapters.length - 1) {
					Swal.fire("Attention", "vous êtes sur le dernier chapitre", 'info');
				} else {
					let find_next_chapter = this.all_chapters[index + 1];
					if (find_next_chapter.locked && !this.access_to_course) {
						this.scrollToPricing(pricing, find_next_chapter);
					} else {
						this.goToChapter(find_next_chapter, true);
					}
				}
			}
		}


	}

	scroll_to_element() {
		const content = document.getElementById('chapters-content');
		const scrollToElement = document.getElementById(this.selected_chapter.slug);
		if (scrollToElement && content) {
			content.scrollTop = scrollToElement.offsetTop - 100;
		}
	}

	callPopupAction(isRedirect: boolean, chapter: Chapter) {
		this.showHelpPage = false;
		if (isRedirect) {
			this.router.navigate(['/courses-details/' + this.formation_slug + '/' + chapter.slug]);
		} else {
			this.callPopupPlayer(chapter.slug);
		}

	}

	check_help_page(chapter: Chapter) {
		return chapter.description.length > 0;
	}

	help_page() {
		this.showHelpPage = !this.showHelpPage;
	}

	GoToDescription(event: any) {
		if (event == 1 && this.formation.description) {
			const planTab = this.tabSet.tabs.find((tab: any) => tab.tabTitle === 'Plan');
			const descriptionTab = this.tabSet.tabs.find((tab: any) => tab.tabTitle === 'Description');

			planTab.active = true; // activate the plan tab
			descriptionTab.active = false; // deactivate the Description tab
			// Delay scrolling to allow time for the DOM to render
			setTimeout(() => {
				// Go to description section within plan tab
				const sectionToDescription = document.getElementById('sectionToDescription');
				if (sectionToDescription) {
					const offsetTop = sectionToDescription.getBoundingClientRect().top;
					// define offSetValue depends of the top navbar, cause when the user is connected the topnavbar is bigger
					let offSetValue = 100;
					if (!this.currentUser.isEmpty) { offSetValue = 140; }
					window.scrollTo({
						top: window.scrollY + offsetTop - offSetValue, // Adjust the offset as needed
						behavior: 'smooth'
					});
				}
			}, 100); // You can adjust the delay time as needed

		}
	}

	addToCart(formationId: string) {
		this.cartService.addToCart(formationId);
	}

}