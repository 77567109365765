import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { PartnerComponent } from '../../common/partner/partner.component';
import { TestimonialsComponent } from '../../common/testimonials/testimonials.component';
import { GetStartedComponent } from '../../common/get-started/get-started.component';
import { CoursesComponent } from '../../common/courses/courses.component';
import { CategoriesComponent } from '../../common/categories/categories.component';
import { FeaturesComponent } from '../../common/features/features.component';
import { HomeoneBannerComponent } from './homeone-banner/homeone-banner.component';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CourseCardModule } from '../../common/course-card/course-card.module';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MobileSectionComponent } from '../../common/mobile-section/mobile-section.component';
import { UpcomingCoursesComponent } from '../../common/upcoming-courses/upcoming-courses.component';
import { SubscribeComponent } from '../../common/subscribe/subscribe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    HomeComponent,
    PartnerComponent,
    TestimonialsComponent,
    GetStartedComponent,
    CoursesComponent,
    UpcomingCoursesComponent,
    CategoriesComponent,
    FeaturesComponent,
    HomeoneBannerComponent,
    MobileSectionComponent,
    SubscribeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ShimmerModule,
    CarouselModule,
    CourseCardModule,
    NgbRatingModule,
    SharedModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class HomeModule { }
