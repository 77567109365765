import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { CourseCardModule } from '../../common/course-card/course-card.module';
import { SharedModule } from '../shared/shared.module';
import { UpcomingCoursesPageRoutingModule } from './upcoming-courses-page-routing.module';
import { UpcomingCoursesPageComponent } from './upcoming-courses-page.component';



@NgModule({
  declarations: [UpcomingCoursesPageComponent],
  imports: [
    CommonModule,
    UpcomingCoursesPageRoutingModule,
    CourseCardModule,
    ShimmerModule,
    SharedModule
  ]
})
export class UpcomingCoursesPageModule { }
