import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MesMoyensPaiementRoutingModule } from './mes-moyens-paiement-routing.module';
import { MesMoyensPaiementComponent } from './mes-moyens-paiement.component';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';


@NgModule({
  declarations: [
    MesMoyensPaiementComponent
  ],
  imports: [
    CommonModule,
    MesMoyensPaiementRoutingModule,
    NgxShimmerLoadingModule,
    ShimmerModule
  ]
})
export class MesMoyensPaiementModule { }
