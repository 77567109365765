<div class="faq-area bg-color-fdfcfc pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="How Do We Create Course Content?" [isOpened]="true">
                            <p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum <strong>porta</strong>. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet <i>aliquam</i> vel aliquet quam id dui posuere blandit <a routerLink="/contact">Contact Us</a>.</p>
                        </accordion-group>
                        <accordion-group heading="How Can I Manage My Account?">
                            <p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel aliquet quam id dui posuere blandit <a routerLink="/contact">Contact Us</a>.</p>
                        </accordion-group>
                        <accordion-group heading="What Are The Benefits Of Edon Learning?">
                            <p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel aliquet quam id dui posuere blandit <a routerLink="/contact">Contact Us</a>.</p>
                        </accordion-group>
                        <accordion-group heading="Is Support For Learners Included?">
                            <p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel aliquet quam id dui posuere blandit <a routerLink="/contact">Contact Us</a>.</p>
                        </accordion-group>
                        <accordion-group heading="How Do I Reset My Account Password?">
                            <p>Curabitur aliquet quam id dui posuere blandit. Cras ultricies ligula sed magna dictum porta. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel aliquet quam id dui posuere blandit <a routerLink="/contact">Contact Us</a>.</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/images/faq-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</div>