import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class VideoService {

  
  entity: string = "/videos";

  constructor(private http: HttpClient, private authService: AuthService) { }

  getByChapterSlug(slug: string): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity +"/getLink/"+slug, header_options);
  }

  test(): Observable<any> {
    return this.http.post(environment.api + this.entity+"/test", {}, httpOptions
    );
  }

}
