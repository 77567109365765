<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">Mon apprentissage</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">mon apprentissage</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container my-section">

        <div class="row">
            <div class="col-md-12">
                <div class="my-courses-tabs">
                    <ngx-tabset >
                        <ngx-tab tabTitle="Tous les cours">
                            <div class="row">
                                <div class="col-xl-3 col-sm-6" *ngFor="let formation of formations">
                                    <app-course-card [formation]="formation" [show_details]="(formation.certificate != '' || formation.pourcentage == 100) ? false : true"></app-course-card>
                                </div>
                                <div class="col-md-12 mt-3 text-center" *ngIf="!loading && load_more">
                                    <div  class="default-btn ng-star-inserted pointer"  (click)="get_more()">Voir plus</div>
                                </div>
                                <div class="col-md-12" *ngIf="formations.length == 0 && !loading">
                                    <p>Aucun résultat trouvé</p>
                                </div>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Cours actives">
                            <div class="row">
                                <div class="col-xl-3 col-sm-6" *ngFor="let formation of formations_not_complete">
                                    <app-course-card [formation]="formation"></app-course-card>
                                </div>
                                <div class="col-md-12" *ngIf="formations_not_complete.length == 0 && !loading">
                                    <p>Aucun résultat trouvé</p>
                                </div>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Cours complétés">
                            <div class="row">
                                <div class="col-xl-3 col-sm-6" *ngFor="let formation of formations_complete">
                                    <app-course-card [formation]="formation" [show_details]="(formation.certificate != '' || formation.pourcentage == 100) ? false : true"></app-course-card>
                                </div>
                                <div class="col-md-12" *ngIf="formations_complete.length == 0 && !loading">
                                    <p>Aucun résultat trouvé</p>
                                </div>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                    
                </div>
            </div>
        </div>

    </div>
</div>