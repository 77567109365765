import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfilComponent } from './mon-profil.component';
import { UserResolver } from 'src/app/services/user/user.resolver';
import { AuthGuard } from 'src/app/_helpers/auth.guard';

const routes: Routes = [
  {
    path: '', component: UserProfilComponent, canActivate: [AuthGuard], resolve: {
        user: UserResolver
    },
    data: {
        title: "Mon profil"
    }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonProfilRoutingModule { }
