<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">Mes factures</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">mes factures</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container my-section">
        <div class="row">
            <!--
                <div class="col-md-12 mb-4">
                <button type="button" class="btn btn-primary btn-right" (click)="manage()">Gérer vos informations de
                    facturation</button>
            </div>
            -->
            <div class="col-md-12" *ngIf="!loading">
                <!-- Purchase History -->
                <div class="db-purchase-history">
                    <div class="table-responsive">
                        <table class="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Total à payer</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date de création</th>
                                    <th scope="col">Date de paiement</th>
                                    <th scope="col">Télécharger la facture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let invoice of invoices">
                                    <td>
                                        <div [innerHTML]="invoice.description"></div>
                                    </td>
                                    <td>{{invoice.amount_due.toFixed(2)}} €</td>
                                    <td>
                                        <span class="badge badge-success"
                                            *ngIf="invoice.status == 'paid'">{{invoice.status_value() }}</span>
                                            <span class="badge badge-secondary"
                                            *ngIf="invoice.status == 'draft'">{{invoice.status_value() }}</span>
                                        <span class="badge badge-danger"
                                            *ngIf="invoice.status != 'paid' && invoice.status != 'draft'">{{invoice.status_value() }}</span>
                                    </td>
                                    <td>{{ timestamp_to_date_format(invoice.created) }} </td>
                                    <td>{{(invoice.paid_at == null) ? "---" : timestamp_to_date_format(invoice.paid_at) }} </td>
                                    <td>
                                        <a class="text-black-50" href="{{ (invoice.status == 'paid') ? invoice.invoice_pdf : invoice.hosted_invoice_url }}"
                                            target="_blank" *ngIf="invoice.status != 'draft'">
                                            <i class="ri-file-download-fill pe-2 text-info"></i>
                                        </a>
                                        <span *ngIf="invoice.status == 'draft'">En cours de traitement...</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-md-12 text-center pt-3" *ngIf="invoices.length == 0 && !loading">
                            <p>Aucun résultat trouvé</p>
                        </div>
                        <div class="col-md-12 mt-3 text-center" *ngIf="load_more">
                            <div  class="default-btn ng-star-inserted pointer"  (click)="get_more()">Voir plus</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="loading">
                <ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>



    </div>
</div>