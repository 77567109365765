<!-- Page Banner -->
<div class="page-banner-area ptb-100" *ngIf="!isLoading">
    <div class="container">
        <div class="page-content text-start">
            <h1>{{ formation.title }}</h1>
            <p class="mb-4">{{ formation.short_description }}</p>
            <ng-template #t let-fill="fill">
                <i class="ri-star-fill" style="color: #d3d3d3">
                    <i *ngIf="fill > 0" class="ri-star-fill filled" [style.width.%]="fill"></i>
                </i>
            </ng-template>
            <ngb-rating [readonly]="true" [starTemplate]="t" [max]="5" [rate]="note" aria-label="Rating"></ngb-rating>
            <span> {{note.toFixed(1)}} ({{formation.reviews.length}})</span>

            <ul class="instructors-status mt-2">
                <li>
                    <img class="miniature-profile" alt="Image" [defaultImage]="defaultImage" [lazyLoad]="formation.profile.thumbnail" [useSrcset]="true">
                    {{ formation.profile.first_name+" "+formation.profile.last_name }}
                </li>
                <li>
                    <i class="ri-time-line"></i>
                    {{ formation.duration }}
                </li>
                <li *ngIf="formation.count_vues != 0">
                    <i class="ri-user-line"></i>
                    {{ formation.count_vues }} Vues
                </li>
            </ul>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li><a routerLink="/courses">Cours</a></li>
                <li class="active">{{ formation.title }}</li>
            </ul>
        </div>
    </div>
</div>

<!-- Courses Details -->
<div class="courses-details-area bg-color-f2f7fa pb-5" *ngIf="!isLoading">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="overview">
                    <ngx-tabset #tabSet (onSelect)="GoToDescription($event)">

                        <ngx-tab tabTitle="Plan">
                            <div class="curriculum-content">

                                <ul class="lesson" *ngFor="let module of formation.modules">
                                    <li class="border-bottom">
                                        <h3>{{ module.title }}</h3>
                                    </li>
                                    <li class="lesson-list" *ngFor="let chapter of module.chapters"
                                        style="background-color: #fff;border-bottom: 1px solid #eeeeee;">
                                        <div (click)="goToChapter(chapter,false)" *ngIf="!chapter.locked"
                                            class="chapter-locked">
                                            <span class="icon-chapter">
                                                <i class="ri-play-circle-line" *ngIf="chapter.type != 'Qcm'"></i>
                                                <i class="ri-questionnaire-line" *ngIf="chapter.type == 'Qcm'"></i>
                                            </span>
                                            <span class="title-chapter">{{ chapter.title }} <span class="text-success"
                                                    *ngIf="chapter.is_read"><i class="ri-check-line"></i></span></span>
                                            <span class="duration-chapter" *ngIf="chapter.type != 'Qcm'">{{
                                                chapter.duration }}</span>
                                        </div>
                                        <div (click)="scrollToPricing(pricing,chapter)"
                                            *ngIf="chapter.locked" class="chapter-unlocked">
                                            <span class="icon-chapter"><i class="ri-lock-line"></i></span>
                                            <span class="title-chapter">{{ chapter.title }}<span class="text-success"
                                                    *ngIf="chapter.is_read"><i class="ri-check-line"></i></span></span>
                                            <span class="duration-chapter" *ngIf="chapter.type != 'Qcm'">{{
                                                chapter.duration }}</span>
                                            <span class="btn-subscription">S'ABONNER</span>
                                        </div>
                                    </li>

                                </ul>

                            </div>
                            <div id="sectionToDescription" *ngIf="formation.description" [innerHTML]="formation.description" class="mb-4 p-4 bg-white"></div>
                        </ngx-tab>
                        <ngx-tab *ngIf="formation.description" tabTitle="Description">
                            <!-- Content of the tab -->
                          </ngx-tab>
                        <ngx-tab tabTitle="Formateur">
                            <div class="instructor-content mb-0">
                                <div class="row ">
                                    <div class="col-lg-5 col-md-12">
                                        <div class="instructors-img">
                                            <img [defaultImage]="defaultImage" [lazyLoad]="formation.profile.large_avatar" [useSrcset]="true" alt="Image"
                                                class="img-former">
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-12">
                                        <div class="instructors-content">
                                            <h3>{{ formation.profile.full_name() }}</h3>
                                            <div [innerHTML]="formation.profile.description"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Avis">
                            <div class="review-content">
                                <div class="row align-items-center">
                                    <div class="col-lg-9">
                                        <div class="rating-point">
                                            <h2>{{note.toFixed(1)}}</h2>
                                            <ul>
                                                <ng-template #t let-fill="fill">
                                                    <i class="ri-star-fill" style="color: #d3d3d3">
                                                        <i *ngIf="fill > 0" class="ri-star-fill filled"
                                                            [style.width.%]="fill"></i>
                                                    </i>
                                                </ng-template>

                                                <ngb-rating [rate]="note" [starTemplate]="t" [readonly]="true"
                                                    [max]="5" aria-label="Rating"></ngb-rating>
                                            </ul>
                                            <span>Note du cours</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">

                                        <ul class="total-review" *ngFor="let item_note of pourcentage_note">
                                            <li><i class="ri-star-fill" *ngFor="let i of arrayOne(item_note.note)"></i>
                                            </li>
                                            <li><i class="ri-star-line"
                                                    *ngFor="let i of arrayOne(5-item_note.note)"></i></li>
                                            <li><span>{{ item_note.pourcentage.toFixed(2) }}%</span></li>
                                        </ul>

                                    </div>
                                </div>
                                <div class="leave-reply"
                                    *ngIf="!currentUser.isEmpty && add_comment && access_to_course">
                                    <h3>laisser un commentaire :</h3>
                                    <form [formGroup]="FormComment" (ngSubmit)="onSubmitReview()">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <img [defaultImage]="defaultImage" [lazyLoad]="currentUser.profile.thumbnail" [useSrcset]="true"
                                                    style="object-fit: cover;height: 50px;width: 50px;border-radius: 50%;margin-right: 10px;"
                                                    alt="Image">
                                                <span>{{ currentUser.full_name() }}</span>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group mb-0">
                                                    <label class="me-2">Note :</label>
                                                    <ng-template #t let-fill="fill">
                                                        <i class="ri-star-fill" style="color: #d3d3d3">
                                                            <i *ngIf="fill > 0" class="ri-star-fill filled"
                                                                [style.width.%]="fill"></i>
                                                        </i>
                                                    </ng-template>

                                                    <ngb-rating formControlName="note" [starTemplate]="t"
                                                        [max]="5" aria-label="Rating"></ngb-rating>
                                                </div>

                                                <div class="form-group">
                                                    <label>Commentaire</label>
                                                    <textarea
                                                        [ngClass]="{'border-danger':comment.comment.errors && submitted}"
                                                        formControlName="comment" name="message" class="form-control"
                                                        id="message" rows="8"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <button type="submit" class="default-btn">Envoyer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <h4>Commentaires :</h4>
                                <p *ngIf="formation.reviews.length == 0">Aucun commentaire trouvé</p>
                                <ul class="feedback-list some-list-1" *ngIf="formation.reviews.length > 0">
                                    <li class="load-more-gallery" *ngFor="let review of formation.reviews">
                                        <img [defaultImage]="defaultImage" [lazyLoad]="review.thumbnail" [useSrcset]="true" alt="Image">
                                        <h3>{{ review.full_name }}</h3>
                                        <ng-template #t let-fill="fill">
                                            <i class="ri-star-fill" style="color: #d3d3d3">
                                                <i *ngIf="fill > 0" class="ri-star-fill filled"
                                                    [style.width.%]="fill"></i>
                                            </i>
                                        </ng-template>
                                        <ngb-rating [readonly]="true" [starTemplate]="t" [max]="5"
                                            [rate]="toNumber(review.note)" aria-label="Rating"></ngb-rating>
                                        <div [innerHTML]="review.comment"></div>
                                        <span style="color: #bebebe;">{{ date_format(review.createdAt) }}</span>
                                    </li>
                                </ul>

                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="widget-sidebar ml-15 pb-70">
                    <div class="sidebar-widget video-courses">
                        <div class="courses-video-img position-relative">
                            <div class="visible-black-bg" *ngIf="formation.is_learning"></div>
                            <div class="progress-circle over50 p100 position-absolute" *ngIf="formation.is_learning">
                                <div class="left-half-clipper">
                                    <div class="value-bar-custom"></div>
                                </div>
                            </div>
                            <div class="progress-circle {{ (formation.pourcentage >= 50) ? 'over50' : '' }} p{{ formation.pourcentage }} position-absolute"
                                *ngIf="formation.is_learning">
                                <span>{{ formation.pourcentage }}%</span>
                                <div class="left-half-clipper">
                                    <div class="first50-bar"></div>
                                    <div class="value-bar"></div>
                                </div>
                            </div>

                            <img class="img-of-course" src="{{ formation.thumbnail }}" alt="Image">
                        </div>
                        <div class="price-status">
                            <h2 *ngIf="formation.price > 0 && !formation.has_promo">{{ formation.price }}€</h2>
                            <span *ngIf="formation.has_promo" class="text-decoration-line-through" style="font-size: 18px !important;font-weight: bold;color:rgb(190, 30, 30);">{{ formation.price }}€</span>       
                            <h2 *ngIf="(formation.price == 0 && !formation.has_promo) || (formation.new_price == 0 && formation.has_promo)">Gratuit</h2>
                            <h2 *ngIf="formation.new_price > 0 && formation.has_promo">{{ formation.new_price }}€</h2>
                            
                            <h4>Ce cours comprend :</h4>
                            <ul class="in-price-list">
                                <li *ngIf="formation.count_vues != 0">
                                    <div class="d-flex">
                                        <i class="ri-eye-fill pe-2 text-info"></i>
                                        {{ formation.count_vues }} vues
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex">
                                        <i class="ri-time-fill pe-2  text-info"></i>
                                        Vidéo de {{ formation.duration }}
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex">
                                        <i class="ri-group-fill pe-2  text-info"></i>
                                        Accès illimité
                                    </div>
                                </li>
                                <li *ngIf="formation.certificate == '' && formation.pourcentage != 100">
                                    <div class="d-flex text-black-100">
                                        <i class="ri-file-text-line pe-2 text-info"></i>
                                        Attestation de formation
                                    </div>
                                </li>
                                <li *ngIf="formation.certificate != '' || formation.pourcentage == 100">
                                    <button class="d-flex bg-white text-black-100" (click)="generate_certificat()">
                                        <i class="ri-file-text-line pe-2 text-info"></i>
                                        Télécharger l'attestation de formation
                                    </button>
                                </li>
                                <li *ngIf="formation.support == '' || formation.support == null">
                                    <div class="d-flex text-black-50">
                                        <i class="ri-file-download-fill pe-2 text-info"></i>
                                        Ressources de formation
                                    </div>
                                </li>
                                <li *ngIf="formation.support != '' && formation.support != null">
                                    <a class="d-flex text-black-50" href="{{ formation.support }}" target="_blank">
                                        <i class="ri-file-download-fill pe-2 text-info"></i>
                                        Télécharger Les ressources de formation
                                    </a>
                                </li>

                            </ul>

                            <ul class="cart-wish-btn" *ngIf="!loading_subscriptipn">
                                <li>
                                    <button type="button" class="default-btn text-uppercase"
                                        (click)="scrollToPricing(pricing)" *ngIf="!access_to_course">Accéder à la
                                        formation</button>
                                    <button type="button" class="default-btn text-uppercase" (click)="start_course()"
                                        *ngIf="access_to_course && !formation.is_learning">Commencer à la
                                        formation</button>
                                    <button type="button" class="default-btn text-uppercase" (click)="start_course()"
                                        *ngIf="access_to_course && formation.is_learning && formation.pourcentage != 100">Poursuivre
                                        la
                                        formation</button>
                                </li>
                                <li *ngIf="formation.price != -1 && !formation.is_in_purchase">
                                    <button (click)="addToCart(formation.id)" type="button" class="course-btn-secondary w-100 mb-2"><i
                                        class="ri-shopping-cart-line"></i>AJOUTER AU PANIER</button>
                                </li>
                            </ul>

                            <ul class="social-link">
                                <li><span>Partagez ce cours :</span></li>
                                <li><button class="bg-dark p-1 pointer" shareButton="facebook" [url]="shareUrl" aria-label="facebook"><i
                                            class="ri-facebook-fill text-white px-1"></i></button></li>
                                <li><button class="bg-dark p-1 pointer" shareButton="telegram" [url]="shareUrl" aria-label="telegram"><i
                                            class="ri-telegram-line text-white px-1"></i></button></li>
                                <li><button class="bg-dark p-1 pointer" shareButton="linkedin" [url]="shareUrl" aria-label="linkedin"><i
                                            class="ri-linkedin-fill text-white px-1"></i></button></li>
                                <li><button class="bg-dark p-1 pointer" shareButton="twitter" [url]="shareUrl" aria-label="twitter"><i
                                            class="ri-twitter-line text-white px-1"></i></button></li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebar-widget related-courses" *ngIf="related_formation.length > 0">
                        <h3>Sélection pour vous</h3>
                        <div class="related-courses-slider">
                            <owl-carousel-o [options]="relatedCoursesSlides">
                                <ng-template carouselSlide *ngFor="let item of related_formation">
                                    <app-suggestion-course-card [formation]="item"
                                        [show_details]="(item.pourcentage == 100) ? false : true"></app-suggestion-course-card>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-100" #pricing>
    <app-pricing [pricing_selected]="pricing_selected" [access_to_course]="access_to_course" [currentUser]="currentUser" 
        [is_connected]="!this.currentUser.isEmpty " *ngIf="!loading_subscriptipn"></app-pricing>
</div>

<ng-template #playVideo let-modal id="popup-player">
    <div class="modal-header border-0 custom-modal-header">
        <h1 class="modal-title fs-5 text-white" id="exampleModalLabel">{{ formation.title }}</h1>
        <button (click)="closeModal(true)" id="close-popup" type="button" class="text-white"><i
                class="fs-2 ri-close-fill"></i></button>
    </div>
    <div class="modal-body py-0">
        <div class="container-fluid">
            <div class="row pb-3">
                <div class="p-4 col-md-8 d-flex justify-content-between modal-instructors-details bg-white">
                    <div class="page-content text-start bg-white">
                        <ul class="instructors-status m-0">
                            <li>
                                <img class="miniature-profile" [defaultImage]="defaultImage" [lazyLoad]="formation.profile.thumbnail" [useSrcset]="true" alt="Image">
                                {{ formation.profile.first_name+" "+formation.profile.last_name }}
                            </li>
                            <li>
                                <i class="ri-time-line"></i>
                                {{ formation.duration }} Heurs
                            </li>
                            <li *ngIf="formation.count_vues != 0">
                                <i class="ri-user-line"></i>
                                {{ formation.count_vues }} Vues
                            </li>
                        </ul>
                    </div>
                    <div class="review m-0">
                        <ng-template #t let-fill="fill">
                            <i class="ri-star-fill" style="color: #d3d3d3">
                                <i *ngIf="fill > 0" class="ri-star-fill filled" [style.width.%]="fill"></i>
                            </i>
                        </ng-template>
                        <ngb-rating [readonly]="true" [starTemplate]="t" [max]="5" [rate]="note" aria-label="Rating"></ngb-rating>
                        <span> {{note.toFixed(1)}} ({{formation.reviews.length}})</span>
                    </div>
                </div>
                <div class="p-4 col-md-4 default-btn pointer modal-instructors-button" *ngIf="!access_to_course">Accéder
                    à la formation
                </div>
                <div class="col-md-4 bg-white" *ngIf="access_to_course"></div>
            </div>
            <div class="row">
                <div class="col-md-8 p-0">

                    <div style="height:450px;" *ngIf="videoLink != '' && selected_chapter.type != 'Qcm'">
                        <iframe *ngIf="selected_chapter.type_video == 'youtube'" id="video-iframe" class="h-100 w-100"
                            [src]="videoLink" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>

                        <div *ngIf="selected_chapter.type_video == 'player'" class="video-player">
                            <app-vjs-player [pause]="pausePlayer" [videoLink]="videoLink"
                                [poster]="formation.thumbnail" [current_learning]="current_learning"
                                [learning_done]="selected_chapter.is_read"
                                (onComplete)="saveCurrentTimeLearning(selected_chapter)"
                                (onUpdateCurrentTime)="onUpdateCurrentTime($event)"></app-vjs-player>

                        </div>
                    </div>
                    <div style="height:450px;" *ngIf="videoLink == '' && selected_chapter.type != 'Qcm'">
                        <img [defaultImage]="defaultImage" [lazyLoad]="formation.thumbnail" [useSrcset]="true" style="width: 100%;height: 100%;object-fit: fill;" alt="Image">
                    </div>

                    <div class="p-4 container" style="width:100%;height:450px;background-color: #fff;overflow-y: scroll;"
                        *ngIf="selected_chapter.type == 'Qcm'">
                        <div class="row" *ngIf="selected_chapter.questions.length > 0">
                            <div class="col-md-12"
                                *ngIf="(selected_chapter.current_question_index+1) <= selected_chapter.questions.length && !showHelpPage">
                                <div class="d-flex justify-content-between">
                                    <h5>Question {{ (selected_chapter.current_question_index+1)}} sur {{
                                        selected_chapter.questions.length }}</h5>
                                        <div *ngIf="check_help_page(selected_chapter)" style="cursor: pointer;" (click)="help_page()"><i class="fas fa-question-circle h4"></i></div>
                                </div>
                                <div style="margin-top:15px;margin-bottom:15px;" [innerHTML]="selected_chapter.questions[selected_chapter.current_question_index].text"></div>
                                <div class="list-group">
                                    <div [ngStyle]="setBgButton(option)" type="button"
                                    class="list-group-item list-group-item-action"
                                    *ngFor="let option of selected_chapter.questions[selected_chapter.current_question_index].optionsFormQuestion"
                                    (click)="choice_question(selected_chapter.questions[selected_chapter.current_question_index],option)" [innerHTML]="option.value"></div>
                                </div>
                                <button class="btn btn-primary mt-3" style="float: left;"
                                    (click)="show_correct_question_qcm()">Vérification</button>
                                <button class="btn btn-success mt-3" style="float: right;"
                                    (click)="next_question_qcm()">Suivant</button>
                            </div>
                            <div class="col-md-12" *ngIf="(selected_chapter.current_question_index+1) <= selected_chapter.questions.length && showHelpPage">
                                        <div style="cursor: pointer;" (click)="help_page()"><i class="fa fa-arrow-left h4"></i></div>
                                        
                                        <div [innerHTML]="selected_chapter.description"></div>
                            </div>
                            <div class="col-md-12"
                                *ngIf="(selected_chapter.current_question_index+1) > selected_chapter.questions.length"
                                style="text-align:center;">
                                <p style="font-size: 18px;">Votre score est :</p>
                                <h2>{{ noteQcm }}/{{ selected_chapter.questions.length }}</h2>
                                <div *ngIf="calcule_pourcentage_qcm() >= 70"><img src="assets/images/icon/happy.svg"
                                        alt="happy" /><br></div>
                                <div *ngIf="calcule_pourcentage_qcm() >= 50 && calcule_pourcentage_qcm() < 70"><img
                                        src="assets/images/icon/normal.svg" alt="normal" /><br></div>
                                <div *ngIf="calcule_pourcentage_qcm() < 50"><img src="assets/images/icon/sad.svg"
                                        alt="sad" /><br></div>
                                <button class="btn btn-primary mt-3" (click)="refresh_qcm()"><i
                                        class="ri-refresh-line"></i> Refaire</button>

                            </div>
                        </div>
                        <div class="row" *ngIf="selected_chapter.questions.length == 0">
                            <img [defaultImage]="defaultImage" [lazyLoad]="formation.thumbnail" [useSrcset]="true" style="width: 100%;height: 100%;object-fit: fill;"
                                alt="Image">
                        </div>
                    </div>

                    <div class="p-3 mt-3" style="max-height: 154px;
                    background-color: rgb(243 243 243);
                    overflow: scroll;">
                        <div class="bg-white p-2 font-weight-bold" style="overflow: auto;">
                            <span style="float: left;">{{ ((selected_chapter.type == 'Qcm') ? 'QCM : ' : 'VIDEO : ')+
                                selected_chapter.title
                                }}</span>
                            <button *ngIf="check_exist_next_chapter()" class="btn btn-info"
                                (click)="next_chapter(pricing)" style="margin-left: 10px !important; float: right;">{{
                                textButtonNext }}<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                            <div *ngIf="!check_exist_next_chapter()" style="float: right;height: 37px;"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 p-0">
                    <div class="h-100 p-3" id="chapters-content"
                        style="overflow: scroll;max-height: 570px;background-color: rgb(243 243 243)">
                        <div class="curriculum-content">
                            <div class="text-center" *ngIf="formation.modules.length==0">
                                La liste est vide
                            </div>
                            <ul class="lesson" *ngFor="let module of formation.modules">
                                <li class="border-bottom">
                                    <h6>{{ module.title }}</h6>
                                </li>
                                <li class="lesson-list" *ngFor="let chapter of module.chapters"
                                    [ngStyle]="setBgSelectedChapter(chapter)" id="{{ chapter.slug }}">
                                    <div (click)="goToChapter(chapter,true)" *ngIf="!chapter.locked"
                                        class="chapter-locked-custom">
                                        <span class="icon-chapter-popup">
                                            <i class="ri-play-circle-line" *ngIf="chapter.type != 'Qcm'"></i>
                                            <i class="ri-questionnaire-line" *ngIf="chapter.type == 'Qcm'"></i>
                                        </span>
                                        <span class="title-chapter-popup">{{ chapter.title }}<span
                                                class="text-success" *ngIf="chapter.is_read"><i
                                                    class="ri-check-line"></i></span></span>
                                        <span [ngStyle]="setBgSelectedChapterDuration(chapter)"
                                            class="duration-chapter-popup" *ngIf="chapter.type != 'Qcm'">{{
                                            chapter.duration }}</span>
                                    </div>
                                    <div (click)="scrollToPricing(pricing,chapter)" *ngIf="chapter.locked"
                                        class="chapter-locked-custom">
                                        <span class="icon-chapter-popup" *ngIf="!access_to_course"><i
                                                class="ri-lock-line"></i></span>
                                        <span class="icon-chapter-popup" *ngIf="access_to_course">
                                            <i class="ri-play-circle-line" *ngIf="chapter.type != 'Qcm'"></i>
                                            <i class="ri-questionnaire-line" *ngIf="chapter.type == 'Qcm'"></i>
                                        </span>
                                        <span class="title-chapter-popup">{{ chapter.title }}<span
                                                class="text-success" *ngIf="chapter.is_read"><i
                                                    class="ri-check-line"></i></span></span>
                                        <span class="duration-chapter-popup" *ngIf="chapter.type != 'Qcm'">{{
                                            chapter.duration }}</span>
                                        <span class="btn-subscription">S'ABONNER</span>
                                    </div>

                                </li>

                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">

    </div>
</ng-template>