import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

declare var AppleID: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class LoginPageComponent implements OnInit {

  @ViewChild('modalRestPassword', { static: true }) modalRestPassword?: ElementRef;
  modalReference: any;

  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  submitForgotPassword = false;
  loading_social_login: boolean = true;
  click_buttons_login: boolean = false;

  client_id: string = "";
  redirect_uri: string = "";
  height: number = window.innerHeight;
  
  return_url: string = "";

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private socialAuthService: SocialAuthService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      identifier: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get f2() { return this.forgetPasswordForm.controls; }

  ngOnInit(): void {
    this.load_buttons();
    this.activatedRoute.queryParams.subscribe(params => {
      this.client_id = (params.client_id) ? params.client_id : "";
      this.redirect_uri = (params.redirect_uri) ? params.redirect_uri : "";
      this.return_url = (params.return) ? params.return : "";
    });
  }

  load_buttons() {
    this.timeout(400).then((res) => {
      this.loading_social_login = false;
    });
  }

  timeout(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    this.click_buttons_login = true;
    this.loading = true;
    this.authService.login(this.f.identifier.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.userService.get(data.jwt).subscribe(user => {
            let userJson = User.toJson(user, data.jwt);
            if (userJson != null) {
              if (user.role != null && user.role.type == "student") {
                localStorage.setItem('currentUser', JSON.stringify(userJson));
                this.authService.callCurrentUserSubject(userJson);
                if (this.client_id == "mobile" && this.redirect_uri == "com.lenidit.app") {
                  this.router.navigate(['/'], { queryParams: { "user": "is_connected" } });
                } else {
                  if (userJson.password_edit_by == "admin") {
                    Swal.fire('Attention', "Veuillez réinitialiser votre mot de passe", 'info');
                    this.router.navigate(['/mon-profil'], { queryParams: { "form": "reset-password" } });
                  } else {
                    this.redirectAfterLogin();
                  }
                }
              } else {
                this.authService.callCurrentUserSubject(new User({}));
                Swal.fire('Error', "Impossible de se connecter avec se compte. \n veuillez contacter le support", 'error');
              }
            } else {
              this.authService.callCurrentUserSubject(new User({}));
              Swal.fire('Error', "Impossible de se connecter avec se compte. \n veuillez contacter le support", 'error');
            }
          });
        },
        error => {
          this.click_buttons_login = false;
          this.loading = false;
          if (error.error.error.message == "Invalid identifier or password") {
            Swal.fire('Erreur', 'Email ou mot de passe invalide', 'error');
            return;
          } else if (error.error.error.message == "Your account has been blocked by an administrator") {
            Swal.fire('Erreur', 'Votre compte a été bloqué', 'error');
            return;
          } else if (error.error.error.message == "Your account email is not confirmed") {

            Swal.fire({
              title: "Votre compte n'est pas activé, Veuillez vérifier votre boite de réception.",
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: "je n'ai pas reçu l'émail d'activation",
              cancelButtonText: "fermer",
              cancelButtonColor: 'red'
            }).then((result) => {
              if (result.isConfirmed) {
                this.spinner.show();
                this.authService.resendConfirmation(this.f.identifier.value).subscribe(res => {
                  Swal.fire("Envoyé", "Un nouveau émail d'activation a été envoyé à votre boite.", "success");
                }, err => {
                  Swal.fire("Érreur", "Une érreur est survenue", "error");
                }).add(() => {
                  this.spinner.hide();
                });
              }
            });
            return;
          } else if (error.error.error.message == "user password not found") {
            Swal.fire('Erreur', "Email ou mot de passe invalide", 'error');
            return;
          } else {
            Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
            return;
          }
        }).add(() => {
          this.spinner.hide();
        });
  }

  resetPassword() {
    this.submitForgotPassword = true;
    if (this.forgetPasswordForm.invalid) {
      Swal.fire('Erreur', 'Veuillez vérifier vos informations', 'error');
      return;
    }
    this.spinner.show();
    this.authService.forgotPassword(this.forgetPasswordForm.value.email).subscribe(
      res => {
        Swal.fire('Envoyé', 'Le lien de réinitialisation a été envoyé.\nVeuillez vérifier votre couriel.', 'success');
        this.spinner.hide();
        this.closeModal();
      },
      err => {
        this.spinner.hide();
        Swal.fire('Error', 'Une érreur s\'est produite veuillez réessayer', 'error');
      }
    )
  }

  loginWithFB() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.checkIfAccountExist("facebook", user);
    }).catch((err) => {
      //Swal.fire('Error', "Une érreur s'est produite veuillez réessayer", 'error');
    });
  }

  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID, {
      scope: 'profile email'
    }).then((user) => {
      this.checkIfAccountExist("google", user);
    }).catch((err) => {
      //Swal.fire('Error', "Une érreur s'est produite veuillez réessayer", 'error');
    });
  }

  async loginWithApple() {
    try {

      AppleID.auth.init({
        clientId: environment.apple_client_id,
        scope: 'name email',
        redirectURI: environment.api_base_link + '/apple/callback',
        state: 'init',
        nonce: 'test',
        usePopup: true
      });

      const data = await AppleID.auth.signIn();
      console.log(this.parseJwt(data.authorization.id_token));



    } catch (error) {
      console.log(error)
      //handle error.
    }

  }



  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  checkIfAccountExist(provider: string, user: any) {
    this.spinner.show();
    this.authService.loginWithSocial(provider, user.authToken).subscribe((data: any) => {
      this.spinner.show();
      this.userService.get(data.jwt).subscribe(user => {
        let userJson = User.toJson(user, data.jwt);
        if (userJson != null) {
          if (user.role != null && user.role.type == "student") {
            localStorage.setItem('currentUser', JSON.stringify(userJson));
            this.authService.callCurrentUserSubject(userJson);
            if (userJson.password_edit_by == "admin") {
              Swal.fire('Attention', "Veuillez réinitialiser votre mot de passe", 'info');
              this.router.navigate(['/mon-profil'], { queryParams: { "form": "reset-password" } });
            } else {
              this.redirectAfterLogin();
            }
          } else {
            this.authService.callCurrentUserSubject(new User({}));
            Swal.fire('Error', "Impossible de se connecter avec se compte. \n veuillez contacter le support", 'error');
          }

        } else {
          this.authService.callCurrentUserSubject(new User({}));
          let params: any = {};
          params.step = "form";
          params.provider = provider;
          params.user_id = user.id;
          params.email = user.email;
          params.username = user.username;
          params.token = data.jwt;
          if(this.return_url != ""){
            params.return = this.return_url;
          }
          this.go_to_register(params);
        }
      }, err => {
        Swal.fire('Error', "Impossible de se connecter avec se compte. \n veuillez contacter le support", 'error');
      }).add(() => {
        this.spinner.hide();
      });
    }, err => {
      Swal.fire('Error', "Une érreur s'est produite veuillez réessayer", 'error');
    }).add(() => {
      this.spinner.hide();
    });
  }

  go_to_register(params: any) {
    this.router.navigate(["/register"], { queryParams: params });
  }

  openModal() {
    this.modalReference = this.modalService.open(this.modalRestPassword, { windowClass: 'popup-modal', ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  closeModal() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.modalReference.close();
  }

  redirectAfterLogin() {
    if (this.return_url != "") {
      this.router.navigate(['/' + this.return_url]);
    } else {
      this.router.navigate(['/']);
    }
  }

  onWindowResize(event: any) {
    this.height = event.target.innerHeight;
  }

}
