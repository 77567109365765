import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterPageRoutingModule } from './register-page-routing.module';
import { RegisterPageComponent } from './register-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShimmerModule } from 'src/app/utils/shimmer/shimmer.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    RegisterPageComponent
  ],
  imports: [
    CommonModule,
    RegisterPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ShimmerModule,
    SharedModule
  ]
})
export class RegisterPageModule { }
