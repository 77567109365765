import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SuggestionCourseCardComponent } from './suggestion-course-card.component';



@NgModule({
  declarations: [
    SuggestionCourseCardComponent
  ],
  exports: [
    SuggestionCourseCardComponent
  ],
  imports: [
    CommonModule,
    NgbRatingModule,
    RouterModule,
    LazyLoadImageModule
  ]
})
export class SuggestionCourseCardModule { }
