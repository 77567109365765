import { Pricing } from "./pricing-model";

export class Subscription {
    id?: number;
    stripe_token?: string;
    active:boolean = false;
    pricing: Pricing = new Pricing();
    createdAt: string = "";
    cancel_at_period_end: boolean = true;
    current_period_end: string = "";
    current_period_start: string = "";

    constructor(data?: Partial<Subscription>) {
        if (data) {
            Object.assign(this, data);
        }
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;
        let objPrcing = obj.pricing.data.attributes;
        objPrcing["id"] = obj.pricing.data.id;
        obj["pricing"] = objPrcing;
        return obj;
    }

}
