<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Privacy Policy</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>

<!-- Privacy Policy -->
<div class="ptb-100">
    <div class="container">
        <div class="main-content-text">
            <h4>1. Personal information:</h4>
            <p>Proin eget tortor risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Curabitur aliquet quam id ut lacinia in elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi leo eget malesuada <a routerLink="/contact">Contact Us</a>.</p>
            <h4>2. Collecting personal information:</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, odio sequi! Nam consectetur, officiis ipsam explicabo tempore placeat! Voluptas molestiae pariatur necessitatibus consectetur, ullam aut esse amet debitis sint culpa. orem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, odio Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae mollitia laborum assumenda beatae minima enim quis dolores, pariatur, at perferendis sit dolore modi nostrum eum ea voluptatem</p>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut, placeat? Culpa ab est nemo perspiciatis quam, nesciunt reprehenderit voluptate id error corrupti doloremque exercitationem quis, iusto debitis velit eveniet ea. ipsum dolor sit amet consectetur, adipisicing elit. Ut, placeat? Culpa ab est nemo perspiciatis quam, nesciunt reprehenderit</p>
            <h4>3. Using and disclosing your personal information:</h4>
            <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero <strong>malesuada</strong> feugiat. Nulla porttitor accumsan tincidunt. Sed porttitor lectus nibh. Pellentesque in ipsum id orci porta dapibus.Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tinci dunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus magna justo.</p>
            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Proin eget tortor risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
            <h4>4. Contact by the company:</h4>
            <p>Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus  libero malesuada feugiat. Proin eget tortor.</p>
            <ul>
                <li>Quisque velit nisi, pretium ut lacinia in, elementum id enim. </li>
                <li>Proin eget tortor risus consectetur adipiscing elit.</li>
                <li>Curabitur aliquet quam id dui posuere blandit.</li>
            </ul>
            <p>Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus  libero malesuada feugiat. Proin eget tortor.</p>
            <h4>5. Individual’s right of access:</h4>
            <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt. Sed porttitor lectus nibh. Pellentesque in ipsum id orci porta dapibus.Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tinci dunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus magna justo</p>
            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Proin eget tortor risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
            <h4>6. The company and links to other web sites:</h4>
            <p>Proin eget tortor risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci.</p>
        </div>
    </div>
</div>