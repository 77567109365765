<div class="join-our-community-area ptb-100 bg-info-subtle">
    <div class="container">
        <div class="our-community-bg">
            <div class="our-community-content">
                <h2>Inscrivez-vous à notre Newsletter !</h2>
                <p>Recevez nos dernières nouvelles et offres spéciales directement dans votre boîte de réception. Abonnez-vous à notre newsletter dès maintenant !
                </p>
                <form class="newsletter-form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <input [ngClass]="{'border-danger':form.controls.email.errors && submitted}" class="form-control" placeholder="Adresse email" type="email" formControlName="email" EmailValidator>
                    <button class="default-btn" type="submit">S'inscrir</button>
                </form>
            </div>
        </div>
    </div>
</div>