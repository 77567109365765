<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Réinitialiser votre mot de passe</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Réinitialisation de mot de passe</li>
            </ul>
        </div>
    </div>
</div>

<!-- Login -->
<div class="user-area ptb-100">
    <div class="container">
        <div class="user-form-content log-in-width">
            <form class="user-form" [formGroup]="FormResetPassword" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-12 col-md--12">
                        <div class="form-group">
                            <label>Mot de passe *</label>
                            <input [ngClass]="{'border-danger':resetPassword.password.errors && submitForgotPassword}" class="form-control" type="password" formControlName="password">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md--12">
                        <div class="form-group">
                            <label>Confirmez votre mot de pass *</label>
                            <input [ngClass]="{'border-danger':resetPassword.confirmPassword.errors && submitForgotPassword}" class="form-control" type="password" formControlName="confirmPassword">
                        </div>
                    </div>
                
                    <div class="col-lg-12 col-md--12">
                        <button class="default-btn" type="submit">Valider</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>