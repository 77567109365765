import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormationService } from 'src/app/services/formation/formation.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CertificateService } from 'src/app/services/certificate/certificate.service';

@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss']
})
export class AttestationComponent implements OnInit {

  loading: boolean = true;
  pdfUrl : any;

  constructor(private certificateService:CertificateService,private spinner: NgxSpinnerService, private formationService: FormationService, private activatedRoute: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(route => {
      this.generateCertificate(route.token);
    });
  }

  generateCertificate(token: string) {
    this.spinner.show();
    this.certificateService.get(token).subscribe((res) => {
      //this.router.navigateByUrl(res); 
    }, err => {

      if (err.status == 200) {
        let pdfBase64String = err.error.text.replace(/^[^,]+,/, '');
        const binaryData = atob(pdfBase64String);
        const uint8Array = Uint8Array.from(binaryData, c => c.charCodeAt(0));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.loading = false;
      } else {
        Swal.fire('Erreur', "Une erreur s'est produite", 'error');
      }

    }).add(() => {
      this.spinner.hide();
    });
  }

}
