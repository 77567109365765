<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Mettre à niveau l'abonnement</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li><a routerLink="/mes-abonnements">Mes abonnements</a></li>
                <li class="active">Mettre à niveau l'abonnement</li>
            </ul>
        </div>
    </div>
</div>

<div class="event-details-area pt-100 pb-70">
    <div class="container">
        <div class="row pl-2">
            <div class="col-md-12 p-4" *ngIf="showForm">
                <div class="row">
                    <div class="col-md-4" *ngIf="new_pricing != null">
                        <div class="single-pricing">
                            <div class="pricing-title">
                                <h3>{{ new_pricing.name }}</h3>
                                <p>Plan tarifaire de base pour l'apprentissage</p>
                                <div class="d-flex">
                                    <h2 class="text-primary">{{(new_pricing.promo_active) ? new_pricing.price_promo :
                                        new_pricing.price }}
                                        €</h2>
                                    <h4 *ngIf="new_pricing.promo_active" class="text-danger ps-3"><span
                                            class="text-decoration-line-through">{{ new_pricing.price }} €</span></h4>
                                </div>
                            </div>
                            <hr>
                            <ul>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Accès à tous les vidéos de formation
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Accès 24/24 et 7/7 pendant toute la période de souscription
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Le catalogue est mis à jour avec de nouvelle vidéo
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Accès Mac, PC, tablettes et smartphone
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Attestation à la fin de chaque formation
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Accès aux QCM de la formation
                                </li>
                                <li *ngFor="let feature of new_pricing.more_features">
                                    <i class="ri-check-line"></i>
                                    {{ feature }}
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Téléchargement des ressources de la formation
                                </li>
                                <li>
                                    <i class="ri-check-line"></i>
                                    Accès aux supports de la formation
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h5 *ngIf="current_subscription != null"><strong>ABONNEMENT ACTUEL :</strong> {{
                            current_subscription.pricing.name }}</h5>
                        <hr>
                        <div class="form-group mt-4" [ngStyle]="setStylesBtnPay()">
                            <label for="exampleInputEmail1">Mes cartes bancaire :</label>
                            <select class="form-control mt-2" [(ngModel)]="selectedPaymentMethod">
                                <option value="">--- Selectionner une carte ---</option>
                                <option value="{{ payment.id }}" *ngFor="let payment of payments">[ {{ payment.brand }}
                                    ] **** **** **** {{ payment.last4 }} - ( Mois d'expiration : {{
                                    payment.exp_month}}/{{ payment.exp_year }} )</option>
                            </select>
                        </div>
                        <div class="form-group mt-2" [ngStyle]="setStylesBtnPay()">
                            <button type="button" class="btn btn-primary m-1" (click)="startSwitch()">Mettre à
                                niveau</button>
                            <button type="button" class="btn btn-secondary m-1" (click)="back()">Retour</button>
                            <button type="button" class="btn btn-warning m-1" (click)="openFormSaveCard()">Ajouter une
                                nouvelle carte</button>
                        </div>
                        <div class="form-group mt-4" [ngStyle]="setStylesBtnSaveCard()">
                            <p>Ajouter une nouvelle carte :</p>
                            <div id="card-element" class="form-control"></div>
                            <div id="card-result"></div>
                        </div>
                        <div class="form-group mt-2" [ngStyle]="setStylesBtnSaveCard()">
                            <button type="button" class="btn btn-success m-1" (click)="saveCard()">Enregistrer</button>
                            <button type="button" class="btn btn-danger m-1"
                                (click)="closeFormSaveCard()">Retour</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 p-4 text-center" *ngIf="!showForm">
                <p>Impossible de mettre à niveau l'abonnement</p>
                <button class="btn btn-primary mt-2" (click)="mes_abonnements()">Mes abonnements</button>
            </div>
        </div>
    </div>
</div>