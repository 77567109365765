import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Slider } from 'src/app/models/slider-model';
import { User } from 'src/app/models/user-model';
import { SliderService } from 'src/app/services/slider/slider.service';

@Component({
  selector: 'app-homeone-banner',
  templateUrl: './homeone-banner.component.html',
  styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

  @Input() currentUser: User = new User({});
  loading: boolean = true;
  sliders: Array<Slider> = [];
  defaultImage: string= "assets/images/default-image.webp";

  constructor(public router: Router, config: NgbCarouselConfig, private sliderService: SliderService) {

    config.interval = 6000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

  }

  ngOnInit(): void {
    this.sliderService.get().subscribe((res) => {
      this.sliders = [];
      res.data.forEach((item: any) => {
        let slider = new Slider(Slider.attributeToJson(item));
        this.sliders.push(slider);
      });

    }).add(() => {
      this.loading = false;
    });
  }

}
