import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/models/category-model';
import { Formation } from 'src/app/models/formation-model';
import { CategoryService } from 'src/app/services/cateogry/category.service';
import { FormationService } from 'src/app/services/formation/formation.service';

@Component({
	selector: 'app-search-page',
	templateUrl: './search-page.component.html',
	styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

	search_value: string = "";
	nbr_items_in_page: number = 12;
	total_items: number = 0;
	current_page: number = 1;
	page_count: number = 1;
	formations: Array<Formation> = [];
	categories: Array<Category> = [];
	loading:boolean = true;

	constructor(private formationService: FormationService, public router: Router, private activatedRoute: ActivatedRoute, private categoryService: CategoryService) { }

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(params => {

			this.current_page = (params["page"]) ? params["page"] : 1;
			this.search_value = (params["q"]) ? params["q"] : "";
			this.getFormations();
			this.get_categories_light();
			/*
			if (this.categoryService.categoriesSubject.value != null) {
				this.categories = this.categoryService.categories;
			} else {
				this.get_categories_light();
			}
			*/

		});

	}


	next() {
		return (this.current_page == this.page_count) ? this.page_count : (Number(this.current_page) + 1);
	}

	previous() {
		return (this.current_page == 1) ? 1 : (Number(this.current_page) - 1);
	}

	getFormations() {
		this.loading = true;
		this.formationService.get_light(this.nbr_items_in_page, this.current_page, "&filters[profile][id][$notNull]=true&filters[is_draft][$eq]=false&filters[title][$contains]=" + this.search_value).subscribe((res: any) => {
			this.total_items = res.meta.pagination.total;
			this.page_count = res.meta.pagination.pageCount;
			this.formations = [];
			res.data.forEach((item: any) => {
				let formation = new Formation(Formation.attributeToJson(item));
				this.formations.push(formation);
			});
		}, err => {
			this.formations = [];
		}).add(() => {
			this.loading = false;
		});
	}

	get_categories_light() {
        this.categoryService.get_light().subscribe(res => {
            this.categories = [];
            res.data.forEach((item: any) => {
                this.categories.push(new Category(Category.attributeToJson(item)));
            });
        });
    }


}
