import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscribe } from 'src/app/models/subsribe-email-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SubscribeEmailService {

  entity: string = "/subscribe-emails";

  constructor(private http: HttpClient) { }

  create(subscribe: Subscribe): Observable<any> {
    return this.http.post(environment.api + this.entity, {
      "data": subscribe
    }, httpOptions
    );
  }

}
