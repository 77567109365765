<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">TARIFICATION</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">tarification</li>
            </ul>
        </div>
    </div>
</div>

<app-pricing [gift_sliders]="gift_sliders" [pricing_selected]="pricing_selected" [access_to_course]="access_to_course" [currentUser]="currentUser" [is_connected]="!this.currentUser.isEmpty" *ngIf="!loading_subscriptipn && !loading_slider"></app-pricing>
