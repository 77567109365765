<!-- Testimonials for Only Home Demo 2 -->
<div class="our-happy-student-area-style-two bg-color-f2f7fa pt-100 pb-70" *ngIf="reviews.length > 0">
    <div class="container">
        <div class="section-title left-title d-flex justify-content-between align-items-center">
            <h2>Ils ont réussi grâce à nos formations</h2>
            <!--
<a routerLink="/testimonials" class="default-btn">Voir tout</a>
            -->
        </div>
        <div class="our-happy-student-slide-two" *ngIf="!loading">
            <owl-carousel-o [options]="testimonialsSlidesTwo">
                <ng-template carouselSlide *ngFor="let review of reviews">
                    <div class="our-happy-student-style-two-item">
                        <div class="d-flex justify-content-between align-items-end">
                            <div class="happy-student-avatar">
                                <img style="width: 130px !important;" src="{{ review.thumbnail }}" alt="Image"
                                    class="img-profile">
                                <div class="d-inline-block">
                                    <h3 class="fullname-profile">{{ review.full_name }}</h3>
                                </div>
                            </div>
                            <ng-template #t let-fill="fill">
                                <i class="ri-star-fill">
                                    <i *ngIf="fill > 0" class="ri-star-fill filled" [style.width.%]="fill"></i>
                                </i>
                            </ng-template>
                            <ngb-rating [readonly]="true" [starTemplate]="t" [max]="5"
                                [rate]="toNumber(review.note)"></ngb-rating>

                        </div>
                        <div [innerHTML]="review.comment"></div>
                        <img src="assets/images/happy-students/quat-s.png" alt="Image" class="quat-s">
                    </div>
                </ng-template>
            </owl-carousel-o>
            <p *ngIf="reviews.length == 0" style="text-align: center;">Aucun résultat trouvé</p>
        </div>
        <app-shimmer [size]="2" type="col-6" *ngIf="loading"></app-shimmer>
    </div>
</div>