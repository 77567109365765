import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AwsService } from '../services/aws/aws.service';

export class Slider {
    id?: number;
    image?: any;
    thumbnail: string = "";
    title: string = "";
    sub_title: string = "";
    description: string = "";
    text_button_mode_connect: string = "";
    link_button_mode_connect: string = "";
    text_button_mode_desconnect: string = "";
    link_button_mode_desconnect: string = "";
    active: boolean = false;
    show_button_mode_connect: boolean = false;
    show_button_mode_desconnect: boolean = false;
    is_gift: boolean = false;
    count_gift: number = 0;
    left_gift: number = 0;

    constructor(data: Partial<Slider>) {
        Object.assign(this, data);
        if (this.thumbnail == "") {
            if (data.image) {
                this.thumbnail = AwsService.generate_link(data.image.url);
            } else {
                this.thumbnail = environment.default_cover;
            }
        }
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;

        if (item.attributes.image) {
            obj["image"] = (item.attributes.image.data == null) ? null : item.attributes.image.data.attributes;
        } else {
            obj["image"] = null;
        }

        obj["thumbnail"] = (obj["image"] == null) ? environment.default_cover : AwsService.generate_link(obj["image"].url);

        return obj;
    }

}
