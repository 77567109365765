import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'src/app/models/subscription-model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import Swal from 'sweetalert2';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mes-abonnements',
  templateUrl: './mes-abonnements.component.html',
  styleUrls: ['./mes-abonnements.component.scss'],
})
export class MesAbonnementsComponent implements OnInit {
  nbr_items_in_page: number = 12;
  total_items: number = 0;
  current_page: number = 1;
  page_count: number = 1;
  currentRoute: string = '';

  loading: boolean = true;
  subscriptions: Array<Subscription> = [];

  stripe: any;
  elements: any;

  constructor(
    private dateFormatterService: DateFormatterService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private subscriptionService: SubscriptionService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentRoute = '/mes-abonnements';
      this.current_page = params['page'] ? params['page'] : 1;
      this.loadStripe();
      this.get_my_subscriptions();
    });
  }

  async loadStripe() {
    this.stripe = await loadStripe(environment.stripe_pk);
    this.elements = this.stripe.elements();
  }

  get_my_subscriptions() {
    this.loading = true;
    this.subscriptionService
      .get(
        '?populate=*&sort[0]=createdAt%3Adesc&pagination[pageSize]=' +
          this.nbr_items_in_page.toString() +
          '&pagination[page]=' +
          this.current_page.toString()
      )
      .subscribe(
        (res) => {
          this.total_items = res.meta.pagination.total;
          this.page_count = res.meta.pagination.pageCount;
          this.subscriptions = [];
          res.data.forEach((item: any) => {
            this.subscriptions.push(
              new Subscription(Subscription.attributeToJson(item))
            );
          });
        },
        (err) => {
          this.subscriptions = [];
          Swal.fire('Erreur', 'Une érreur est survenue', 'error');
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  manage() {
    this.spinner.show();
    this.stripeService
      .manageBilling()
      .subscribe(
        (res) => {
          window.open(res.url, '_blank');
        },
        (err) => {
          Swal.fire('Erreur', 'Une érreur est survenue', 'error');
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  go_to_factures(subscription: Subscription) {
    this.router.navigate(['/mes-factures'], {
      queryParams: { subscription: subscription.stripe_token },
    });
  }

  switch(subscription: Subscription) {
    if (subscription) {
      Swal.fire({
        title: "Étes-vous sûre de vouloir mettre à niveau l'abonnement ?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `OUI`,
        cancelButtonText: `NON`,
        cancelButtonColor: 'red',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(
            ['/abonnements/mettre-a-niveau/' + subscription.stripe_token],
            { queryParams: {} }
          );
        }
      });
    } else {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: false })
      .then(() => this.router.navigate([uri]));
  }

  date_format(date: any) {
    return this.dateFormatterService.showDateHour(date);
  }

  cancel_subscription_at_period_end(subscription: Subscription) {
    if (
      subscription.active &&
      subscription.stripe_token != '' &&
      subscription.stripe_token != null
    ) {
      Swal.fire({
        title:
          'Étes-vous sûre de vouloir ' +
          (subscription.cancel_at_period_end ? 'activé' : 'désactivé') +
          ' le renouvellement automatique ?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `OUI`,
        cancelButtonText: `NON`,
        cancelButtonColor: 'red',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.stripeService
            .cancel_subscription_at_period_end({
              subscription: subscription.stripe_token,
              cancel_at_period_end: !subscription.cancel_at_period_end,
            })
            .subscribe(
              (res) => {
                Swal.fire(
                  'Félicitation',
                  'Renouvellement automatique a été ' +
                    (subscription.cancel_at_period_end
                      ? 'activé'
                      : 'désactivé') +
                    ' avec succès',
                  'success'
                );
                this.activatedRoute.queryParams.subscribe((params) => {
                  if (params.return_to) {
                    this.router.navigate(['/' + params.return_to]);
                  } else {
                    this.get_my_subscriptions();
                  }
                });
              },
              (err) => {
                if (err.error.error.message == 'no.cards.found') {
                  Swal.fire({
                    title: 'Attention',
                    html: '<p>Aucune carte de paiement trouvée. Veuillez en ajouter une pour activer le renouvellement automatique.</p>',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: `Mes moyens de paiement`,
                    cancelButtonText: `Annuler`,
                    cancelButtonColor: 'red',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.router.navigate(['/mes-moyens-paiement'], {
                        queryParams: {
                          return_to: 'mes-abonnements',
                          form: 'add',
                        },
                      });
                    }
                  });
                } else {
                  Swal.fire('Erreur', 'Une érreur est survenue', 'error');
                }
              }
            )
            .add(() => {
              this.spinner.hide();
            });
        }
      });
    }
  }

  timestamp_to_date_format(timestamp: any) {
    let value = '';
    if (timestamp != '' && timestamp != null && timestamp != 'null') {
      let date = new Date(timestamp * 1000);
      value = this.dateFormatterService.showDateHour(date);
    } else {
      value = '---';
    }
    return value;
  }

  next() {
    return this.current_page == this.page_count
      ? this.page_count
      : Number(this.current_page) + 1;
  }

  previous() {
    return this.current_page == 1 ? 1 : Number(this.current_page) - 1;
  }

}
