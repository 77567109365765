<div class="categories-area pb-70">
    <div class="container">
        <div class="section-title left-title">
            <h2>Explorez les catégories</h2>
        </div>
        <div class="categories-slide" *ngIf="!loading">
            <owl-carousel-o [options]="categoriesSlides">
                <ng-template carouselSlide *ngFor="let category of categories">
                    <div class="single-categories-item">
                        <a routerLink="/category/{{ category.slug }}" class="categories-img">
                            <div class="categorie-container text-center">
                                <img class="categorie-img mx-auto my-3" [defaultImage]="defaultImage" [lazyLoad]="category.thumbnail" [useSrcset]="true" alt="Image">
                                <h3>{{ category.name }}</h3>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
            <p *ngIf="categories.length == 0" style="text-align: center;">Aucun résultat trouvé</p>
        </div>
        <app-shimmer [size]="4" type="col-3" *ngIf="loading"></app-shimmer>
    </div>
</div>