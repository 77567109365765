import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingPageComponent } from './pricing-page.component';
import { AdminAuthGuard } from 'src/app/_helpers/admin-auth.guard';

const routes: Routes = [
  {
    path: '', component: PricingPageComponent,
    data: {
      title: "Tarification"
    }, canActivate: [AdminAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingPageRoutingModule { }
