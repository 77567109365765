import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from '../models/user-model';
import { AuthService } from '../services/auth/auth.service';
import { AuthAdminService } from '../services/auth-admin/auth-admin.service';

@Injectable({ providedIn: 'root' })
export class LogoutGuard implements CanActivate {
  constructor(
    private router: Router,
    private authAdminService: AuthAdminService,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authAdminService.currentUserSubject.subscribe(
        (admin) => {
          let is_logged = admin != null;
          if (is_logged) {
            let currentUser: any;
            this.authService.currentUserSubject.subscribe((user) => {
              currentUser = new User(user);
            });

            if (currentUser && !currentUser.isEmpty) {
              this.router.navigate(['/']);
              resolve(false);
            }

            resolve(true);
          } else {
            this.router.navigate(['/coming-soon']);
            resolve(false);
          }
        },
        (err) => {
          this.router.navigate(['/coming-soon']);
          resolve(false);
        }
      );
    });
  }

  /*

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentUser:any;
        this.authenticationService.currentUserSubject.subscribe(user => {
            currentUser=new User(user)
        })
        
        if (currentUser && !currentUser.isEmpty) {
            // logged in so return false
            this.router.navigate(['/']);
            return false;
        }

        // not logged in 
        return true;
    }
    */
}
