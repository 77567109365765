import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Formation } from 'src/app/models/formation-model';
import { Profile } from 'src/app/models/profile-model';
import { FormationService } from 'src/app/services/formation/formation.service';


@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {


	formations: Array<Formation> = [];
	loading = true;

	constructor(private formationService: FormationService, public router: Router) { }

	ngOnInit(): void {
		this.formationService.get_light(8, 1,"&filters[is_draft][$eq]=false").subscribe((res: any) => {
			res.data.forEach((item: any) => {
				let formation = new Formation(Formation.attributeToJson(item));
				this.formations.push(formation);
			});
		}, err => {
			this.formations = [];
		}).add(() => {
			this.loading = false;
		});
	}

}