<app-homeone-banner [currentUser]="currentUser"></app-homeone-banner>

<app-features></app-features>

<app-categories></app-categories>

<app-upcoming-courses></app-upcoming-courses>

<app-mobile-section></app-mobile-section>

<app-courses></app-courses>

<app-subscribe></app-subscribe>

<!--
<app-achievement></app-achievement>
-->

<app-get-started *ngIf="currentUser.isEmpty && !loading"></app-get-started>

<app-testimonials></app-testimonials>

<app-partner></app-partner>

<script>
    $('.carousel').carousel()
</script>