<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">Mes abonnements</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">mes abonnements</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container my-section">
        <div class="row">
            <!--
                <div class="col-md-12 mb-4">
                <button type="button" class="btn btn-primary btn-right" (click)="manage()">Gérer vos informations de
                    facturation</button>
            </div>
            -->
            <div class="col-md-12" *ngIf="!loading">
                <!-- Purchase History -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="db-purchase-history">
                            <div class="table-responsive">
                                <table class="table align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Abonnement</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Date d'abonnement</th>
                                            <th scope="col">Date de fin</th>
                                            <th scope="col">date de <br>renouvèlement</th>
                                            <th scope="col">Renouvellement <br>automatique (*)</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let subscription of subscriptions">
                                            <td>{{ subscription.pricing.name }}</td>
                                            <td>
                                                <span class="badge badge-success" *ngIf="subscription.active">Activé</span>
                                                <span class="badge badge-danger" *ngIf="!subscription.active">Annulé</span>
                                            </td>
                                            <td>{{ date_format(subscription.createdAt) }}</td>
                                            <td>{{ (subscription.cancel_at_period_end) ? timestamp_to_date_format(subscription.current_period_end)  : "----" }}</td>
                                            <td>{{ (!subscription.cancel_at_period_end && subscription.active) ? timestamp_to_date_format(subscription.current_period_end)  : "----" }}</td>
                                            <td>
                                                <button class="custom-btn w-100 mt-2 text-danger"
                                                    *ngIf="subscription.cancel_at_period_end && subscription.active"
                                                    (click)="cancel_subscription_at_period_end(subscription)">
                                                    <i class="fa fa-times" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Cliquez sur le boutton pour activé"></i> Désactivé
                                                </button>
                                                <button class="custom-btn w-100 mt-2 text-success"
                                                    *ngIf="!subscription.cancel_at_period_end && subscription.active"
                                                    (click)="cancel_subscription_at_period_end(subscription)">
                                                    <i class="fa fa-check" aria-hidden="true" data-placement="bottom" title="Cliquez sur le boutton pour désactivé"></i> Activé
                                                </button>
                                                <button class="custom-btn w-100 mt-2"*ngIf="!subscription.active">---</button>
                                            </td>
                                            <td>
                                                <button *ngIf="subscription.stripe_token != '' && subscription.stripe_token != null" class="btn btn-info" style="margin-left: 10px;margin-bottom: 5px;" (click)="go_to_factures(subscription)">Les
                                                    factures</button>
                                                <button class="btn btn-warning" style="margin-left: 10px;margin-bottom: 5px;"
                                                    *ngIf="subscription.stripe_token != '' && subscription.stripe_token != null  && subscription.active && subscription.pricing.duration == 1" (click)="switch(subscription)">Mettre à
                                                    niveau l'abonnement</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="col-md-12 text-center pt-3" *ngIf="subscriptions.length == 0 && !loading">
                                    <p>Aucun résultat trouvé</p>
                                </div>
                                <p class="mt-5">(*) Pour activé/désactivé le renouvellement automatique Cliquez sur le bouton sous le titre <strong>Renouvellement automatique</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="subscriptions.length > 0 && page_count > 1">
                        <div class="pagination-area">
                            <a [routerLink]="currentRoute" [queryParams]="{page: previous() }"
                                class="prev page-numbers"><i class="ri-arrow-left-line"></i> Précédent </a>

                            <a [routerLink]="currentRoute" class="next page-numbers"
                                [queryParams]="{page: next() }">Suivant
                                <i class="ri-arrow-right-line"></i> </a>
                            <span class="page-numbers current" aria-current="page">{{ current_page +' sur '+
                                page_count}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="loading">
                <ngx-shimmer-loading class="m-0 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading class="mb-1 p-0" [shape]="'rect'" [height]="'50px'"
                    [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>


    </div>
</div>