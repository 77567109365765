import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from 'src/app/models/profile-model';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';

declare var AppleID: any;

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  return_url: string = '';
  step: string = 'mode';
  provider: string = 'email';
  user_id: string = '';
  token: string = '';
  email: string = '';
  username: string = '';
  form!: FormGroup;
  submitted = false;
  startDate: any;
  endDate: any;
  loading_social_register: boolean = true;

  constructor(
    private userService: UserService,
    private socialAuthService: SocialAuthService,
    private activatedRoute: ActivatedRoute,
    private ngbCalendar: NgbCalendar,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
    private stripeApi: StripeService
  ) {}
  //validator submit
  get f() {
    return this.form.controls;
  }

  load_buttons() {
    this.timeout(400).then((res) => {
      this.loading_social_register = false;
    });
  }

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true };
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.load_buttons();
      this.return_url = params.return ? params.return : '';
      console.log('this.return_url : ' + this.return_url);

      this.step = params.step ? params.step : this.step;
      this.provider = params.provider ? params.provider : this.provider;
      this.user_id = params.user_id ? params.user_id : this.user_id;
      this.token = params.token ? params.token : this.token;
      this.email = params.email ? params.email : this.email;
      this.username = params.username ? params.username : this.username;

      this.form = this.formBuilder.group({
        last_name: ['', Validators.required],
        email: [
          '',
          this.provider != 'email'
            ? []
            : [Validators.required, Validators.email],
        ],
        title: ['monsieur', Validators.required],
        first_name: ['', Validators.required],
        privacy_policy: [false],
        password: [
          '',
          this.provider != 'email'
            ? []
            : [Validators.required, Validators.minLength(6)],
        ],
        confirmPassword: [
          '',
          this.provider != 'email'
            ? []
            : [Validators.required, Validators.minLength(6)],
        ],
      });
      // date de naissance
      // ceux qui ont moins de 100 ans
      this.startDate = this.beforeDate(100);
      // ceux qui ont plus de 15 ans
      this.endDate = this.beforeDate(15);
    });
  }

  beforeDate(year: any) {
    return this.ngbCalendar.getPrev(this.ngbCalendar.getToday(), 'y', year);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      let message = '';
      //(this.FormContact.get('email')?.invalid && this.FormContact.get('email')?.touched
      if (
        this.form.controls['first_name'].errors?.required ||
        this.form.controls['last_name'].errors?.required
      ) {
        message = 'Veuillez vérifier vos informations';
      } else if (this.form.controls['password'].errors?.required) {
        message = 'Le champ "Mot de passe" est requis';
      } else if (this.form.controls['password'].errors?.minlength) {
        message = 'Le mot de passe doit contenir au moins 6 caractères';
      } else if (this.form.controls['confirmPassword'].errors?.required) {
        message = 'Le champ "Confirmez votre mot de passe" est requis';
      } else if (this.form.value.password !== this.form.value.confirmPassword) {
        message = 'Les mots de passe ne correspondent pas';
      } else {
        message = 'Veuillez vérifier vos informations';
      }
      Swal.fire(
        'Error',
        '<p>' +
          message +
          '</p>' +
          (this.form.get('email')?.invalid && this.form.get('email')?.touched
            ? "<p>Le format de l'adresse email n'est pas valide</p>"
            : ''),
        'error'
      );
      return;
    }

    if (!this.form.value.privacy_policy) {
      Swal.fire(
        'Attention',
        "Veuillez accepter les conditions d'utilisation du site",
        'info'
      );
      return;
    }

    if (this.provider == 'email') {
      let ob: any = this.checkPasswords(this.form);
      if (ob && ob['notSame'] == true) {
        Swal.fire(
          'Erreur',
          'les mots de passe ne sont pas identiques',
          'error'
        );
        return;
      }
    }
    let user: any = {};
    let profile: any = {};

    this.spinner.show();
    if (this.provider == 'email') {
      user.email = this.form.value.email;
      user.password = this.form.value.password;
      user.username = this.form.value.email;
      user.first_name = this.form.value.first_name;
      user.last_name = this.form.value.last_name;
      user.title = this.form.value.title;
    }

    profile.email =
      this.provider == 'email' ? this.form.value.email : this.email;
    profile.first_name = this.form.value.first_name;
    profile.last_name = this.form.value.last_name;
    profile.title = this.form.value.title;

    if (this.provider == 'email') {
      this.authService.register(user).subscribe(
        (res) => {
          Swal.fire(
            'Félicitation',
            'Votre inscription a été validée. Pour pouvoir vous connecter, nous vous invitons à cliquer sur le lien que vous avez reçu sur votre adresse email pour valider votre compte.',
            'success'
          );
          this.form.reset();
          this.submitted = false;
          this.spinner.hide();
          if (this.return_url != '') {
            this.router.navigate(['/login'], {
              queryParams: { return: this.return_url },
            });
          } else {
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          if (
            err.error.error.message.includes(
              'Email or Username are already taken'
            )
          ) {
            if (
              err.error.error.message == 'Email or Username are already taken'
            ) {
              Swal.fire('Erreur', "L'adresse email est déjà utilisée", 'error');
            } else {
              try {
                const parsedObject = JSON.parse(err.error.error.message);
                if (parsedObject.provider == 'local') {
                  Swal.fire(
                    'Erreur',
                    'Un compte a déjà été créé avec cette adresse e-mail en utilisant Mot de passe',
                    'error'
                  );
                } else {
                  Swal.fire(
                    'Erreur',
                    'Un compte a déjà été créé avec cette adresse e-mail en utilisant ' +
                      parsedObject.provider,
                    'error'
                  );
                }
              } catch (error) {
                Swal.fire(
                  'Erreur',
                  "L'adresse email est déjà utilisée",
                  'error'
                );
              }
            }
          } else {
            Swal.fire('Erreur', 'Une érreur est survenue', 'error');
          }
          this.spinner.hide();
        }
      );
    } else if (this.provider == 'google' || this.provider == 'facebook') {
      profile.user = this.user_id;
      this.create_profile(profile, this.token);
    } else {
      this.spinner.hide();
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }
  }

  create_profile(profile: any, jwt: string) {
    this.profileService
      .create(profile, jwt)
      .subscribe((profileAdded) => {
        this.userService.get(jwt).subscribe((user) => {
          let userJson = User.toJson(user, jwt);
          if (userJson != null) {
            Swal.fire(
              'Félicitation',
              'Votre inscription a été validée.',
              'success'
            );
            localStorage.setItem('currentUser', JSON.stringify(userJson));
            this.authService.callCurrentUserSubject(userJson);
            if (this.return_url != '') {
              this.router.navigate(['/' + this.return_url]);
            } else {
              this.router.navigate(['/']);
            }
          } else {
            Swal.fire(
              'Error',
              'Impossible de se connecter avec se compte. \n veuillez contacter le support',
              'error'
            );
          }
        });
      })
      .add(() => {
        this.spinner.hide();
      });
  }

  handleAddressChange(place: any) {
    let city,
      postal = '';
    place.address_components.filter((component: any) => {
      switch (component.types[0]) {
        case 'locality': // city
          city = component.long_name;
          return true;
        case 'postal_code': // postal code
          postal = component.long_name;
          return true;
        default:
          return false;
      }
    });

    this.form.controls.address.setValue(place.formatted_address);
    this.form.controls.city.setValue(city);
  }

  to_form(provider: string) {
    this.provider = provider;
    if (provider == 'email') {
      this.step = 'form';
      this.generate_params();
    } else if (provider == 'google') {
      this.loginWithGoogle();
    } else if (provider == 'facebook') {
      this.loginWithFB();
    } else if (provider == 'apple') {
      this.loginWithApple();
    } else {
      this.provider = 'email';
      this.step = 'form';
      this.generate_params();
    }
  }

  generate_params() {
    let params: any = {};
    params.step = this.step;
    params.provider = this.provider;
    params.user_id = this.user_id;
    params.email = this.email;
    params.username = this.username;
    params.token = this.token;
    if (this.return_url != '') {
      params.return = this.return_url;
    }
    this.redirectTo('/register', params);
  }

  redirectTo(uri: string, params: any) {
    this.router
      .navigateByUrl('/', { skipLocationChange: false })
      .then(() => this.router.navigate([uri], { queryParams: params }));
  }

  loginWithFB() {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.checkIfAccountExist('facebook', user);
      })
      .catch((err) => {
        Swal.fire(
          'Error',
          "Une érreur s'est produite veuillez réessayer",
          'error'
        );
      });
  }

  loginWithGoogle(): void {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.checkIfAccountExist('google', user);
      })
      .catch((err) => {
        Swal.fire(
          'Error',
          "Une érreur s'est produite veuillez réessayer",
          'error'
        );
      });
  }

  async loginWithApple() {
    try {
      AppleID.auth.init({
        clientId: environment.apple_client_id,
        scope: 'email',
        redirectURI: environment.api_base_link + '/apple/callback',
        state: 'init',
        usePopup: true,
      });
      const data = await AppleID.auth.signIn();
      console.log(data);
    } catch (error) {
      console.log(error);
      //handle error.
    }
  }

  checkIfAccountExist(provider: string, user: any) {
    this.spinner.show();
    this.authService
      .loginWithSocial(provider, user.authToken)
      .subscribe(
        (data: any) => {
          this.spinner.show();
          this.userService
            .get(data.jwt)
            .subscribe(
              (user) => {
                let userJson = User.toJson(user, data.jwt);

                if (userJson != null) {
                  Swal.fire(
                    'Erreur',
                    "L'adresse email est déjà utilisée",
                    'error'
                  );
                  this.spinner.hide();
                } else {
                  this.step = 'form';
                  this.token = data.jwt;
                  this.user_id = user.id;
                  this.username = user.username;
                  this.email = user.email;
                  this.generate_params();
                }
              },
              (err) => {
                Swal.fire(
                  'Error',
                  "Impossible de s'inscrire avec cette adresse email. \n veuillez contacter le support",
                  'error'
                );
              }
            )
            .add(() => {
              this.spinner.hide();
            });
        },
        (err) => {
          Swal.fire(
            'Error',
            "Une érreur s'est produite veuillez réessayer",
            'error'
          );
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  back() {
    let params: any = {};
    if (this.return_url != '') {
      params.return = this.return_url;
    }
    this.redirectTo('/register', params);
  }
}
