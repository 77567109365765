import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pricing } from 'src/app/models/pricing-model';
import { Subscription } from 'src/app/models/subscription-model';
import { StripeService } from 'src/app/services/stripe/stripe.service';

import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { PaymentMethod } from 'src/app/models/payment-method-model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-switch-subscription',
  templateUrl: './switch-subscription.component.html',
  styleUrls: ['./switch-subscription.component.scss']
})
export class SwitchSubscriptionComponent implements OnInit {

  stripe: any;
  cardElement: any;
  elements: any;

  styleBtnsPay: string = "block";
  styleBtnsSaveCard: string = "none";

  subscription_token: string = "";
  new_pricing?: Pricing;
  current_subscription?: Subscription;
  showForm: boolean = true;

  payments: Array<PaymentMethod> = [];
  selectedPaymentMethod: string = "";

  constructor(private stripeService: StripeService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private authService: AuthService, public router: Router , private location: Location) { }

  ngOnInit(): void {

    this.getSubscriptionByToken();
  }

  getSubscriptionByToken() {
    this.activatedRoute.params.subscribe(route => {
      this.subscription_token = (route.subscription) ? route.subscription : "";
      if (this.subscription_token != "") {
        this.spinner.show();
        this.stripeService.get_subscription_by_token(this.subscription_token).subscribe((res: any) => {
          this.current_subscription = new Subscription(res.current_subscription);
          this.new_pricing = new Pricing(Pricing.fillData(res.new_plan));
          
          this.loadStripe();
          this.getCards();
          this.show_form();
        }, err => {
          this.show_form();
          this.spinner.hide();
        });
      } else {
        this.show_form();
        this.spinner.hide();
      }
    });

  }

  show_form(){
    this.showForm = (this.subscription_token != null && this.new_pricing != null) ? true : false; 
  }

  getCards() {
    this.spinner.show();
    this.stripeService.mes_paiements().subscribe(res => {
      this.payments = [];
      res.data.forEach((item: any) => {
        this.payments.push(new PaymentMethod(PaymentMethod.attributeToJson(item)));
      });
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(() => {
      this.spinner.hide();
    });
  }

  async loadStripe() {
    this.stripe = await loadStripe(environment.stripe_pk);
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card-element');
    this.spinner.hide();
  }

  setStylesBtnPay() {
    let styles = {
      "display": this.styleBtnsPay,
    };
    return styles;
  }

  setStylesBtnSaveCard() {
    let styles = {
      "display": this.styleBtnsSaveCard,
    };
    return styles;
  }

  openFormSaveCard() {
    this.styleBtnsPay = "none";
    this.styleBtnsSaveCard = "block";
  }

  closeFormSaveCard() {
    this.loadStripe();
    this.styleBtnsPay = "block";
    this.styleBtnsSaveCard = "none";
    this.getCards();
    
  }

  saveCard() {
    this.spinner.show();
    this.stripeService.create_payment_intent_card().subscribe((res: any) => {
      this.stripe.handleCardSetup(
        res.client_secret,
        this.cardElement,
        {
          payment_method_data: {
            billing_details: {
              name: this.authService.currentUserValue.profile.full_name
            }
          }
        }
      ).then((result: any) => {
        if (result.error) {
          this.spinner.hide();
          let errorMessage = result.error;
          let messageText = "";
          switch (errorMessage.code) {
            case 'incomplete_zip':
              messageText = "Votre code postal est incomplet.";
              break;
            case 'incomplete_cvc':
              messageText = "Le code de sécurité de votre carte est incomplet.";
              break;
            case 'incomplete_expiry':
              messageText = "La date d'expiration de votre carte est incomplète.";
              break;
            case "incomplete_number":
              messageText = "Votre numéro de carte est incomplet.";
              break;
            default:
              messageText = "Votre numéro de carte est invalide.";
              break;
          }
          Swal.fire('Erreur', messageText, 'error');


        } else {
          this.stripeService.attach_card(result.setupIntent.payment_method).subscribe(res => {
            this.spinner.hide();
            this.closeFormSaveCard();
            Swal.fire('Félicitation', "La carte a été ajoutée avec succès !", 'success');
          }, err => {
            this.spinner.hide();
            Swal.fire('Erreur', 'Une érreur est survenue', 'error');

          });
        }
      });
    }, (err) => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    });

  }

  startSwitch() {
    if(this.selectedPaymentMethod == ""){
      Swal.fire('Erreur', 'veuillez sélectionner une carte bancaire !', 'error');
    }else{
      this.spinner.show();
      this.stripeService.switch(this.subscription_token, this.selectedPaymentMethod).subscribe((res) => {
  
        if (res.code == 200) {
          Swal.fire('Félicitation', "l'abonnement a été mettre a niveau avec succès", 'success');
          this.mes_abonnements();
        } else {
          Swal.fire('Erreur', 'Une érreur est survenue', 'error');
        }
      }, err => {
        Swal.fire('Erreur', 'Une érreur est survenue', 'error');
  
      }).add(() => {
        this.spinner.hide();
      });
    }
    
  }

  mes_abonnements() {
    this.router.navigate(['/mes-abonnements']);
  }

  back() {
    this.location.back();
  }

}
