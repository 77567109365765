import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/cateogry/category.service';
import { Category } from 'src/app/models/category-model';
import { environment } from 'src/environments/environment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  categories: Array<Category> = [];

  constructor(
    private categoryService: CategoryService,private ccService: NgcCookieConsentService
  ) { }

  ngOnInit(): void {
    /*
    if (this.categoryService.categoriesSubject.value != null) {
      this.categories = this.categoryService.categories;
    } else {
      this.get_categories_light();
    }
    */
    this.get_categories_light();
  }

  get_categories_light() {
    this.categoryService.get_light().subscribe(res => {
      this.categories = [];
      res.data.forEach((item: any) => {
        this.categories.push(new Category(Category.attributeToJson(item)));
      });
    });
  }

  openCookies(){
    this.ccService.open();
  }

}
