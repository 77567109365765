import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, OnInit, SimpleChanges, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { Learning } from 'src/app/models/learning-model';
import { VideoService } from 'src/app/services/video/video.service';
declare var videojs: any;

@Component({
  selector: 'app-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class VjsPlayerComponent implements OnInit, OnChanges {

  @ViewChild('target', { static: true }) target!: ElementRef;
  @Input() videoLink!: string;
  @Input() current_learning!: Learning;
  @Input() poster!: string;
  @Input() slug!: string;
  @Input() pause: boolean = false;
  @Output() public onComplete: EventEmitter<any> = new EventEmitter();
  @Input() completedParam: any;
  @Input() learning_done: boolean = false;
  player!: any;
  videoError!: boolean;
  qualityLevels: any;
  options = {
    notSupportedMessage: 'URL de la vidéo non valide',
  };
  loading: boolean = true;
  someInterval: any;
  currentTime: any = 0;
  start_saving: boolean = false;
 

  @Output() onUpdateCurrentTime = new EventEmitter<any>();

  constructor(private videoService: VideoService, private ngZone: NgZone) { }

  ngOnInit(): void {
    this.saveInterval();
  }

  ngAfterViewInit(): void {
    this.readyVideojsPlayer();
  }

  saveInterval() {
    this.ngZone.runOutsideAngular(() => {
      this.someInterval = setInterval(() => {
        if (this.start_saving && !this.learning_done) {
          this.onUpdateCurrentTime.emit(this.currentTime);
          this.onComplete.emit();
        }
      }, 10000)
    });
  }

  readyVideojsPlayer() {
    this.player = videojs(this.target.nativeElement, this.options, function () { });
    this.loading = false;
    this.player.src({
      src: this.videoLink
    });


    var duration = 0;

    this.player.on('ready', () => {
      let start_time = (this.current_learning) ? ((this.current_learning.duration) ? ((Number(this.current_learning.duration.toFixed(0)) == Number(this.current_learning.chapter.duration.toString())) ? 0 : this.current_learning.duration) : 0) : 0;
      this.player.currentTime(start_time);
    });
    this.player.on('timeupdate', () => {
      duration = this.player.duration();
      this.currentTime = this.player.currentTime();
      this.start_saving = true;
    });
    this.player.on('pause', () => {
      this.start_saving = false;
    });
    this.player.on('dispose', () => {
      this.start_saving = false;
      this.onUpdateCurrentTime.emit(this.currentTime);
      this.onComplete.emit();
    });

    this.player.on('ended', () => {
      this.start_saving = false;
      this.onUpdateCurrentTime.emit(this.currentTime);
      this.onComplete.emit();
      this.learning_done = true;
    });



  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pause) {
      if (changes.pause.currentValue) {
        if (this.player) {
          this.player.pause();
        }
      }
    }

  }

  reloadURL(): void {
    this.videoService.getByChapterSlug(this.slug).subscribe((res) => {
      this.videoLink = res.data.value;
      this.readyVideojsPlayer();
      this.videoError = false;
    });
  }

}
