export class Invoice {

    id!: string;
    invoice_pdf!: string;
    description!: string;
    status!: string;
    amount_due!: number;
    amount_paid!: number;
    created!: string;
    paid_at!: string;
    hosted_invoice_url!: string;

    constructor(data: Partial<Invoice>) {
        Object.assign(this, data);
    }

    status_value() {
        return (this.status == "paid") ? "Payé" : ((this.status == "draft") ? "Brouillon" : "Impayé");
    }

    static attributeToJson(item: any) {
        let obj = item;

        var description = '<ul>';
        item.lines.data.forEach((element: any) => {
            description +='<li>'+ element.description+'</li>';
        });
        description += '</ul>';

        obj["description"] = description;
        obj["paid_at"] = item.status_transitions.paid_at;
        obj["amount_due"] = item.amount_due / 100;
        obj["amount_paid"] = item.amount_paid / 100;
        return obj;
    }

}