<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h1 *ngIf="current_category_name != ''">{{ current_category_name }}</h1>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Catégories
                <li class="active">{{ slug }}
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Courses -->
<div class="popular-courses-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-md-3 categories pb-3">
                <h3>Catégories</h3>
                <ul>
                    <li *ngFor="let category of categories">
                        <div class="my-custom-link pointer" (click)="go_to_category(category)" style="cursor: pointer;"
                            [ngStyle]="setColorNameCategory(category)">
                            {{ category.name }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-9 pb-5">
                <div class="showing-result">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="showing-result-count">
                                <p *ngIf="formations.length > 0">Nous avons trouvé <strong>{{ total_items }}</strong>
                                    cours disponibles pour vous</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!--
<div class="showing-top-bar-ordering">
                                <label>Trier par :</label>
                                <select class="form-select">
                                    <option selected>Default</option>
                                    <option value="1">Popularity</option>
                                    <option value="2">Latest</option>
                                    <option value="3">Price: low to high</option>
                                    <option value="3">Price: high to low</option>
                                </select>
                            </div>
                            -->

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" *ngIf="formations.length == 0 && !loading">
                        <h4 style="text-align: center;">Aucun résultat trouvé</h4>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="row" *ngIf="!loading">
                            <div class="col-xl-4 col-sm-6"
                                *ngFor="let formation of (!loading ? formations : 6 | arraySize)">
                                <app-course-card [formation]="formation" [loading]="loading"
                                    [show_details]="(formation.certificate != '' || formation.pourcentage == 100) ? false : true"></app-course-card>
                            </div>
                        </div>
                        <app-shimmer [size]="9" type="col-course-4" *ngIf="loading"></app-shimmer>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="formations.length > 0 && page_count > 1">
                        <div class="pagination-area">
                            <a [routerLink]="currentRoute" [queryParams]="{page: previous() }"
                                class="prev page-numbers"><i class="ri-arrow-left-line"></i> Précédent </a>

                            <a [routerLink]="currentRoute" class="next page-numbers"
                                [queryParams]="{page: next() }">Suivant
                                <i class="ri-arrow-right-line"></i> </a>
                            <span class="page-numbers current" aria-current="page">{{ current_page +' sur '+
                                page_count}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="p-5 bg-light position-relative" *ngIf="category_description">
        <h2 class="text-center">Description</h2>
        <div [innerHTML]="category_description" class="mb-4 p-4 text-center"></div>
    </div> -->

    <div class="p-5 bg-light" *ngIf="category_description" style="position: relative;">
        <div class="leftMotif"></div>
        <div class="topLeftMotif"></div>
        <div class="bottomRightMotif"></div>
        <div class="topRightMotif"></div>
        <h2 class="text-center mb-3">{{ current_category_name }}</h2>
        <div [innerHTML]="category_description" class="mb-4 description text-center"></div>
    </div>
</div>