import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/cateogry/category.service';
import { Category } from 'src/app/models/category-model';

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

	loading: boolean = true;
	categories: Array<Category> = [];
	defaultImage: string= "assets/images/default-image-category.webp";

	constructor(
		public router: Router,
		private categoryService: CategoryService
	) { }

	ngOnInit(): void {
		/*
		if (this.categoryService.categoriesSubject.value != null) {
			this.categories = this.categoryService.categories;
			this.loading = false;
		} else {
			this.get_categories_light();
		}
*/
		this.get_categories_light();
	}

	setStyles(url: string) {
		let styles = {
			"background-image": "url(" + url + ")",
		};
		return styles;
	}

	categoriesSlides: OwlOptions = {
		items: 1,
		nav: true,
		loop: true,
		margin: 30,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
			"<i class='ri-arrow-left-s-line'></i>",
			"<i class='ri-arrow-right-s-line'></i>",
		],
		responsive: {
			0: {
				items: 1
			},
			414: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 3
			},
			992: {
				items: 4
			},
			1200: {
				items: 5
			}
		}
	}

	get_categories_light() {
		this.categoryService.get_light().subscribe(res => {
			this.categories = [];
			res.data.forEach((item: any) => {
				this.categories.push(new Category(Category.attributeToJson(item)));
			});
		}).add(() => {
			this.loading = false;
		});
	}

}