<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">TARIFICATION</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li><a routerLink="/tarification">tarifications</a></li>
            </ul>
        </div>
    </div>
</div>
<div class="container text-center my-section">
    <div class="row">
        <div class="col-md-12">
            <p>Votre abonnement a échoué, merci de réessayer</p>
            <button class="btn btn-primary w-100 mt-5" (click)="mes_abonnements()">Mes abonnements</button>
        </div>
    </div>
</div>