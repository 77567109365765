import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { ReviewService } from 'src/app/services/review/review.service';
import { Review } from 'src/app/models/review-model';

@Component({
	selector: 'app-testimonials',
	templateUrl: './testimonials.component.html',
	styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

	loading: boolean = true;
	reviews: Array<Review> = [];
	constructor(
		public router: Router,
		private reviewService: ReviewService
	) { }

	ngOnInit(): void {
		this.reviewService.get_home_page().subscribe((res) => {
			this.reviews = [];
			res.data.forEach((item: any) => {
				this.reviews.push(new Review(Review.attributeToJson(item)));
			});
		}, (err) => {
			this.reviews = [];
		}).add(() => {
			this.loading = false;
		});
	}

	testimonialsSlides: OwlOptions = {
		items: 1,
		nav: true,
		loop: true,
		margin: 30,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		animateOut: 'fadeOut',
		autoplayHoverPause: true,
		navText: [
			"<i class='ri-arrow-left-s-line'></i>",
			"<i class='ri-arrow-right-s-line'></i>"
		]
	}
	testimonialsSlidesTwo: OwlOptions = {
		items: 1,
		loop: true,
		margin: 30,
		nav: false,
		dots: false,
		autoplay: false,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
			"<i class='ri-arrow-left-s-line'></i>",
			"<i class='ri-arrow-right-s-line'></i>",
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 2
			},
			1200: {
				items: 2
			}
		}
	}

	toNumber(value: any) {
		return Number(value);
	}

}