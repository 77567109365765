<!-- Page Banner -->
<div class="page-banner-area ptb-100" *ngIf="show_profile_form == 'reset-password'">
    <div class="container">
        <div class="page-content">
            <h2>Réinitialiser votre mot de passe</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Réinitialisation de mot de passe</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container">
        <!-- Settings -->
        <div class="db-enrolled-settings-form">
            <div class="row">
                <div class="col-xl-8 col-lg-12 col-md-12 mx-auto" *ngIf="show_profile_form != 'reset-password'">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="currentUser">
                        <h3>Mes informations</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <input hidden (change)="uploadFile($event)" accept="image/gif, image/jpeg, image/png"
                                    type="file" #inputFile>
                                <div style="height: 152px;width: 152px;"
                                    class="mx-auto p-0 rounded-circle overflow-auto">
                                    <div style="height: 152px;width: 152px;"
                                        class="mx-auto p-0 rounded-circle border position-relative">
                                        <img class="profil" [src]="image" alt="user image">
                                        <button type="button" style="left: 0;
                            bottom: 0;
                            font-size: small;
                            background: #00000038;" (click)='inputFile.click()'
                                            class="w-100 btn text-white position-absolute">
                                            changer
                                            <i style="font-size: small;" class="fas fa-pen"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label>Titre *</label><br>
                                <div class="form-check form-check-inline">
                                    <input formControlName="title" class="form-check-input" type="radio"
                                        id="inlineRadio1" value="monsieur">
                                    <label class="form-check-label" for="inlineRadio1">Monsieur</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input formControlName="title" class="form-check-input" type="radio"
                                        id="inlineRadio2" value="madame">
                                    <label class="form-check-label" for="inlineRadio2">Madame</label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Prénom :</label>
                                    <input type="text" class="form-control" formControlName="first_name"
                                        [ngClass]="{'border-danger':formControls.first_name.errors && submitted}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Nom :</label>
                                    <input type="text" class="form-control" formControlName="last_name"
                                        [ngClass]="{'border-danger':formControls.last_name.errors && submitted}">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Téléphone</label>
                                    <input type="text" class="form-control" formControlName="phone"
                                        [ngClass]="{'border-danger':formControls.phone.errors && submitted}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email :</label>
                                    <input readonly type="email" class="form-control" formControlName="email"
                                        style="cursor:not-allowed;">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Date de naissance :</label>
                                    <div class="input-group">
                                        <input [maxDate]="endDate" [minDate]="startDate" class="form-control"
                                            placeholder="dd/mm/yyyy" name="d" formControlName="date_of_birthday"
                                            [ngClass]="{'border-danger':formControls.date_of_birthday.errors && submitted}"
                                            ngbDatepicker #d="ngbDatepicker"  />
                                        <button class="btn btn-outline-secondary ri ri-calendar-2-fill"
                                            (click)="d.toggle()" type="button"></button>
                                    </div>
                                </div>
                            </div>
                            <!--
                                <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Spécialité</label>
                                    <div class="form-floating">
                                        <select class="form-select" id="floatingSelect" formControlName="speciality">
                                            <option value="particular">Un particulier</option>
                                            <option value="company">Une entreprise</option>
                                            <option value="school">Une école ou une université</option>
                                            <option value="administration">Une administration</option>
                                            <option value="other">Autre</option>
                                        </select>
                                        <label for="floatingSelect">Je suis</label>
                                    </div>
                                </div>
                            </div>
                            -->
                            <!-- formateur -->
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea class="form-control" formControlName="description" rows="6"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Site web</label>
                                    <input type="text" class="form-control" formControlName="web_site">
                                </div>
                            </div> -->
                            <!--
                            <div class="col-lg-6 col-sm-6 mb-4">
                                <div class="form-group">
                                    <label>Adresse *</label>
                                    <input [ngClass]="{'border-danger':formControls.address.errors && submitted}"
                                        formControlName="address" #placesRef ngx-google-places-autocomplete
                                        placeholder="veuillez sélectionner une adresse"
                                        (onAddressChange)="handleAddressChange($event)" type="text"
                                        class="form-control">
                                </div>
                            </div>
                            -->
                            

                            <!-- social media -->
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Twitter</label>
                                    <input type="text" class="form-control" formControlName="twitter">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Facebook</label>
                                    <input type="text" class="form-control" formControlName="facebook">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>LinkedIn</label>
                                    <input type="text" class="form-control" formControlName="linkedin">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Youtube</label>
                                    <input type="text" class="form-control" formControlName="youtube">
                                </div>
                            </div> -->
                            
                            
                            
                        </div>
                        <div class="row">
                            <div class="col-md-12 my-4">
                                <h3>Informations de facturation</h3>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Adresse :</label>
                                    <input type="text" class="form-control" formControlName="short_address"
                                        [ngClass]="{'border-danger':formControls.short_address.errors && submitted}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Ville :</label>
                                    <input type="text" class="form-control" formControlName="city"
                                        [ngClass]="{'border-danger':formControls.city.errors && submitted}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Code postal :</label>
                                    <input type="text" class="form-control" formControlName="postal_code"
                                        [ngClass]="{'border-danger':formControls.postal_code.errors && submitted}">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Pays :</label>
                                    <input type="text" class="form-control" formControlName="country"
                                        [ngClass]="{'border-danger':formControls.country.errors && submitted}">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <button type="submit" class="default-btn w-100">Modifier le Profile</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12 mx-auto mt-4">
                    <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()">
                        <h3>Réinitialiser le mot de passe</h3>
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Nouveau mot de passe</label>
                                    <input type="password" class="form-control" formControlName="password"
                                        [ngClass]="{'border-danger':resetPassFormControls.password.errors && submittedResetPassword}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Confimer le mot de passe</label>
                                    <input type="password" class="form-control" formControlName="passwordConfirmation"
                                        [ngClass]="{'border-danger':resetPassFormControls.passwordConfirmation.errors && submittedResetPassword}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <button type="submit" class="default-btn w-100">Réinitialiser le mot de passe</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>