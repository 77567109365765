import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner/partner.service';
import { Partner } from 'src/app/models/patner-model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

	loading: boolean = true;
	partners: Array<Partner> = [];

	constructor(
		public router: Router,
		private partnerService: PartnerService
	) { }

	ngOnInit(): void {
		this.partnerService.get().subscribe((res: any) => {

			res.data.forEach((element: any) => {
				this.partners.push(new Partner(Partner.attributeToJson(element)));
			});

		}).add(()=> {
			this.loading = false;
		});
	}

	partnerSlides: OwlOptions = {
		loop: true,
		margin: 30,
		nav: false,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		responsive: {
			0: {
				items: 2
			},
			414: {
				items: 3
			},
			576: {
				items: 4
			},
			768: {
				items: 5
			},
			992: {
				items: 6
			},
			1200: {
				items: 6
			}
		}
	}

}