import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/models/category-model';
import { Formation } from 'src/app/models/formation-model';
import { SeoService } from 'src/app/seo/seo.service';
import { CategoryService } from 'src/app/services/cateogry/category.service';
import { FormationService } from 'src/app/services/formation/formation.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-courses-category-page',
	templateUrl: './courses-category-page.component.html',
	styleUrls: ['./courses-category-page.component.scss']
})
export class CoursesCategoryPageComponent implements OnInit {

	slug: string = "";
	nbr_items_in_page: number = 12;
	total_items: number = 0;
	current_page: number = 1;
	page_count: number = 1;
	formations: Array<Formation> = [];
	categories: Array<Category> = [];
	loading = true;
	current_category_name: string = "";
	category_description: string = "";

	currentRoute: string = "";
	

	constructor(private formationService: FormationService, public router: Router, private activatedRoute: ActivatedRoute, private categoryService: CategoryService, private seoService: SeoService) { }

	ngOnInit(): void {

		this.activatedRoute.params.subscribe(route => {

			this.slug = route.slug;
			this.currentRoute = "/category/" + this.slug;
			this.activatedRoute.queryParams.subscribe(params => {

				this.current_page = (params["page"]) ? params["page"] : 1;
				/*
				if (this.categoryService.categoriesSubject.value != null) {
					this.categories = this.categoryService.categories;
				} else {
					this.get_categories_light();
				}
*/
				this.get_categories_light();
				this.get_formations();

			});
		});
	}

	next() {
		return (this.current_page == this.page_count) ? this.page_count : (Number(this.current_page) + 1);
	}

	previous() {
		return (this.current_page == 1) ? 1 : (Number(this.current_page) - 1);
	}

	get_formations() {

		this.loading = true;
		this.formationService.get_light(this.nbr_items_in_page, this.current_page, "&filters[profile][id][$notNull]=true&filters[is_draft][$eq]=false&filters[categories][slug][$eq]=" + this.slug).subscribe((res: any) => {
			this.total_items = res.meta.pagination.total;
			this.page_count = res.meta.pagination.pageCount;
			this.formations = [];
			res.data.forEach((item: any) => {
				let formation = new Formation(Formation.attributeToJson(item));
				this.formations.push(formation);
			});
		}, err => {
			this.formations = [];
		}).add(() => {
			this.loading = false;
		});
	}

	get_categories_light() {
		this.categoryService.get_light().subscribe(res => {
			this.categories = [];
			res.data.forEach((item: any) => {
				this.categories.push(new Category(Category.attributeToJson(item)));
			});

			let found_category = this.categories.find((item: Category) => {
				return item.slug == this.slug;
			});
			if (!found_category) {
				this.router.navigate(['/category/' + this.slug + '/error/404']);
			} else {
				
				let shareUrl = environment.url + 'category/' + found_category.slug;

				let title: string = found_category.meta_title_google;
				let desription: string = found_category.meta_description_google;
				// meta description
				this.seoService.generateTags({
					title: (title != "" && title != null) ? title : "Categorie",
					url: shareUrl,
					description: (desription != "" && desription != null) ? desription.replace(/<[^>]*>/g, '') : "",
					image: found_category.thumbnail,
				});

				this.current_category_name = found_category.name!;
				this.category_description = found_category.description!;
			}
		});
	}

	go_to_category(category: Category) {
		this.redirectTo("/category/" + category.slug);
	}

	redirectTo(uri: string) {
		this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
			this.router.navigate([uri]));
	}

	setColorNameCategory(category: Category) {
		let styles = {
			"color": (this.slug == category.slug) ? "#007daa" : "#000 !important"
		};
		return styles;
	}

}
