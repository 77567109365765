import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user-model';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactService } from 'src/app/services/contact/contact.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  currentUser: User = new User({});
  FormContact!: FormGroup;
  submitFormContact = false;

  constructor(private authService:AuthService,private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private contactService: ContactService) {
    this.FormContact = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = new User(user);

      if(!this.currentUser.isEmpty){
        this.FormContact.patchValue(this.currentUser.profile);
        this.FormContact.controls.full_name.setValue(this.currentUser.profile.last_name+" "+this.currentUser.profile.first_name);
      }
      
    });
   
    
  }

  get contact() {
    return this.FormContact.controls;
  }

  onSubmit() {
    this.submitFormContact = true;
    if (this.FormContact.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html: '<p>Veuillez vérifier vos informations</p>' +
          (this.FormContact.get('email')?.invalid && this.FormContact.get('email')?.touched ? "<p>Le format de l'adresse email n'est pas valide</p>" : ''),
        confirmButtonText: 'OK'
      });
      return
    }
    this.spinner.show();
    this.contactService.create(this.FormContact.value).subscribe(res => {
      Swal.fire('Envoyé', 'votre message a bien été envoyé', 'success');
      this.FormContact.reset();
      this.submitFormContact = false;
    }, err => {

    }).add(() => {
      this.spinner.hide();
    });
  }



}
