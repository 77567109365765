import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterPageComponent } from './register-page.component';
import { LogoutGuard } from 'src/app/_helpers/logout.guard';

const routes: Routes = [
  {
    path: '', component: RegisterPageComponent, canActivate: [LogoutGuard],
    data: {
        title: "Inscription"
    }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterPageRoutingModule { }
