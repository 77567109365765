import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonProfilRoutingModule } from './mon-profil-routing.module';
import { UserProfilComponent } from './mon-profil.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    UserProfilComponent
  ],
  imports: [
    CommonModule,
    MonProfilRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTypeaheadModule
  ]
})
export class MonProfilModule { }
