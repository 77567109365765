<div class="container d-flex align-items-center justify-content-center my-5">
    <div class="pt-4 px-4 text-center border col-lg-5 bg-white rounded-4 shadow container-register">
        
        <a class="navbar-brand" routerLink="/"><img class="mb-4" src="assets/images/logo.png" alt="logo"></a>
        <h2 class="text-body-emphasis">Créer un compte</h2>
        <div  class="register-custom-form mt-5" *ngIf="step == 'mode'">
            <div class="w-100" *ngIf="!loading_social_register">
                <button type="submit" class="btn btn-info my-1 w-100" (click)="to_form('email')">S'inscrire avec votre
                    Email</button>
                <button type="submit" class="btn btn-danger my-1 w-100" (click)="to_form('google')">S'inscrire avec
                    Google</button>
                <button type="submit" class="btn btn-primary my-1 w-100" (click)="to_form('facebook')">S'inscrire avec
                    Facebook</button>
                    <div class="my-5">
                        <h4 class="lined-text" style="color: #6a6f73;"><span>OU</span></h4>
                    </div>
                    <a [routerLink]="['/login']" 
                    [queryParams]="(return_url != '') ? { return: return_url } : {}"  class="btn btn-secondary w-100">Se connecter</a>
                    
            </div>
            <div class="w-100" *ngIf="loading_social_register">
                <app-shimmer [size]="3" type="button-col-12"></app-shimmer>
            </div>
        </div>
        <div class="register-custom-form" *ngIf="step == 'form'" >
            <form class="user-form mt-4" [formGroup]="form" (ngSubmit)="onSubmit()">
                    
                <div class="row" style="text-align: left;">
                    <div class="col-md-12 mb-4">
                        <label class="mb-2">Titre *</label><br>
                        <div class="form-check form-check-inline">
                            <input formControlName="title" class="form-check-input" type="radio" id="inlineRadio1"
                                value="monsieur">
                            <label class="form-check-label" for="inlineRadio1">Monsieur</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input formControlName="title" class="form-check-input" type="radio" id="inlineRadio2"
                                value="madame">
                            <label class="form-check-label" for="inlineRadio2">Madame</label>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="form-group"><label>Nom *</label><input
                                [ngClass]="{'border-danger':f.last_name.errors && submitted}" type="text"
                                name="last_name" formControlName="last_name" class="form-control"></div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="form-group"><label>Prénom *</label><input
                                [ngClass]="{'border-danger':f.first_name.errors && submitted}" type="text"
                                name="first_name" formControlName="first_name" class="form-control"></div>
                    </div>
                    <div class="col-md-12 mb-4" *ngIf="provider == 'email'">
                        <div class="form-group"><label>Email
                                *</label><input [ngClass]="{'border-danger':f.email.errors && submitted}" type="email"
                                name="email" formControlName="email" class="form-control" EmailValidator>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4" *ngIf="provider == 'email'">
                        <div class="form-group"><label>Mot de passe
                                *</label><input
                                [ngClass]="{'border-danger':(f.password.errors || !!checkPasswords(form)) && submitted}"
                                type="password" name="password" formControlName="password" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-12 mb-4" *ngIf="provider == 'email'">
                        <div class="form-group"><label>Confirmez le mot de passe
                                *</label><input
                                [ngClass]="{'border-danger':(f.confirmPassword.errors || !!checkPasswords(form)) && submitted}"
                                type="password" name="repeat-password" formControlName="confirmPassword"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="privacy_policy"
                                id="flexCheckDefault">
                            <label style="color: var(--paragraphColor);" class="form-check-label create mb-3"
                                for="flexCheckDefault">
                                J'accepte les conditions d'utilisation du site ainsi que la <a
                                    routerLink="/privacy-policy">politique de confidentialité</a>
                            </label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary w-100">Valider</button>
                    <button (click)="back()" class="btn btn-dark mt-2 w-100"><i class="fa fa-angle-left"></i> Retour</button>
                </div>
            </form>
        </div>
        <p *ngIf="step == 'form'" class="create mt-3">Vous avez déjà un compte ? <a href="/login"><strong>Connexion</strong></a></p>
        <p class="text-copyright">© 2024 <strong>LENID IT</strong><br>TOUS DROITS SONT RÉSERVÉS</p>
    </div>
</div>


