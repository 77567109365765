<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2 class="text-uppercase">Mes moyens de paiement</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">mes moyens de paiement</li>
            </ul>
        </div>
    </div>
</div>
<div class="p-lg-5 p-2">
    <div class="container my-section">
        <div class="row">
            <!--
                <div class="col-md-12 mb-4">
                <button type="button" class="btn btn-primary btn-right" (click)="manage()">Gérer vos informations de
                    facturation</button>
            </div>
            -->
            <div class="col-md-12 mb-4">
                <button class="btn btn-xl btn-success" style="float: right;" [ngStyle]="setStylesBtnAdd()" (click)="openFormSaveCard()">Ajouter une nouvelle carte</button>

                <div class="form-group mt-4" [ngStyle]="setStylesBtnSaveCard()">
                    <p>Ajouter une nouvelle carte :</p>
                    <div id="card-element" class="form-control"></div>
                    <div id="card-result"></div>
                </div>
                <div class="form-group mt-2" style="float: right;" [ngStyle]="setStylesBtnSaveCard()">
                    <button type="button" class="btn btn-success m-1" (click)="saveCard()">Enregistrer</button>
                    <button type="button" class="btn btn-danger m-1"
                        (click)="closeFormSaveCard()">Fermer</button>
                </div>
            </div>
            <div class="col-md-12" *ngIf="!loading">
                <!-- Purchase History -->
                <div class="db-purchase-history">
                    <div class="table-responsive">
                        <table class="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Type de carte</th>
                                    <th scope="col">Numéro de carte</th>
                                    <th scope="col">Mois d'expiration</th>
                                    <th scope="col">Année d'expiration</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of payments">
                                    <td>{{payment.brand}}</td>
                                    <td>**** **** **** {{payment.last4}}</td>
                                    <td>{{payment.exp_month}}</td>
                                    <td>{{payment.exp_year}}</td>
                                    <td>
                                        <button title="Supprimer" class="btn btn-sm btn-icon-only text-danger" (click)="remove_payment_method(payment.id)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-md-12 text-center pt-3" *ngIf="payments.length == 0 && !loading">
                            <p>Aucun résultat trouvé</p>
                        </div>
                    </div>
                </div>
            </div>
            <app-shimmer [size]="8" type="button-col-12" *ngIf="loading"></app-shimmer>
        </div>


    </div>
</div>