import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmationEmailComponent } from './confirmation-email.component';
import { LogoutGuard } from 'src/app/_helpers/logout.guard';

const routes: Routes = [
  {
    path: '', component: ConfirmationEmailComponent, canActivate: [LogoutGuard],
    data: {
        title: "Votre compte a été activé avec succès"
    }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfirmationEmailRoutingModule { }
