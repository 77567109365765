import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user-model';
import { SeoService } from 'src/app/seo/seo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = true;
  currentUser: User = new User({});

  constructor(private authService: AuthService, private seoService: SeoService) { }

  ngOnInit(): void {
    // meta description
    this.seoService.generateTags({
      title: "Cours en ligne - Apprenez à votre rythme, ce que vous voulez",
      url: environment.url,
      description: "Le Nid est bien plus qu'un simple marché en ligne , c'est un vaste écosystème dédié à l'apprentissage et à l'enseignement dans le monde numérique contemporain.",
      image: environment.url + "assets/images/courses-video-img-2.png",
    });
    
    this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = new User(user);
    }).add(() => {
      this.loading = false;
    });



  }


}
