import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FormationService {

  entity: string = "/formations";

  constructor(private http: HttpClient, private authService: AuthService) { }

  get_light(pageSize: number, page: number, filters?: string): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity + "?filters[active][$eq]=true&populate=modules.chapters,couverture,profile.avatar,reviews&sort[0]=createdAt%3Adesc&pagination[pageSize]=" + pageSize.toString() + "&pagination[page]=" + page.toString() + ((filters) ? filters : ""), header_options);
  }

  get(pageSize: number, page: number, filters?: string): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity + "?filters[active][$eq]=true&filters[is_draft][$eq]=false&populate=couverture,profile.avatar,categories,modules.chapters,reviews.student.avatar&sort[0]=createdAt%3Adesc&pagination[pageSize]=" + pageSize.toString() + "&pagination[page]=" + page.toString() + ((filters) ? filters : ""), header_options);
  }

  getBySlug(slug: string): Observable<any> {
    let header_options = (this.authService.currentUserValue == null) ? httpOptions : ((this.authService.currentUserValue.isEmpty) ? httpOptions : { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
    return this.http.get(environment.api + this.entity + "/" + slug, header_options);
  }

  apprentissage(pageSize: number, page: number) {
    return this.http.get(environment.api + this.entity + "/courses/apprentissage/?pagination[pageSize]=" + pageSize.toString() + "&pagination[page]=" + page.toString(), { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token, 'Content-Type': 'application/json' } });
  }

  getCartItems(ids: any): Observable<any> {
    return this.http.post(environment.api + this.entity + "/get/filtering", { courses: ids });
  }

}
