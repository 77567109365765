import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesCategoryPageComponent } from './courses-category-page.component';
import { AdminAuthGuard } from 'src/app/_helpers/admin-auth.guard';

const routes: Routes = [
  { path: '', component: CoursesCategoryPageComponent, canActivate: [AdminAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesCategoryPageRoutingModule { }
