import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingsSuccessComponent } from './pricings-success.component';
import { AuthGuard } from 'src/app/_helpers/auth.guard';

const routes: Routes = [
  {
    path: '', component: PricingsSuccessComponent,
    data: {
      title: "Opération effectuée avec succès"
    }, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingsSuccessRoutingModule { }
