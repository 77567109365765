import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentMethod } from 'src/app/models/payment-method-model';
import { Subscription } from 'src/app/models/subscription-model';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import Swal from 'sweetalert2';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-mes-moyens-paiement',
  templateUrl: './mes-moyens-paiement.component.html',
  styleUrls: ['./mes-moyens-paiement.component.scss']
})
export class MesMoyensPaiementComponent implements OnInit {

  stripe: any;
  cardElement: any;
  elements: any;

  styleBtnsSaveCard: string = "none";
  styleBtnAddCard: string = "block";


  loading: boolean = true;
  payments: Array<PaymentMethod> = [];

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private stripeService: StripeService, private spinner: NgxSpinnerService, public router: Router, private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.form) {
        if (params.form == "add") {
          this.openFormSaveCard();
        }
      }
      this.loadStripe();
      this.get_my_payments();
    });

  }

  get_my_payments() {
    this.spinner.show();
    this.stripeService.mes_paiements().subscribe(res => {
      this.payments = [];
      res.data.forEach((item: any) => {
        this.payments.push(new PaymentMethod(PaymentMethod.attributeToJson(item)));
      });
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(() => {
      this.loading = false;
      this.spinner.hide();
    });
  }

  manage() {
    this.spinner.show();
    this.stripeService.manageBilling().subscribe((res) => {
      window.open(res.url, "_blank");
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(() => {
      this.spinner.hide();
    });
  }

  remove_payment_method(token: string) {

    Swal.fire({
      title: "Étes-vous sûre de vouloir supprimer ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: `OUI`,
      cancelButtonText: `NON`,
      cancelButtonColor: 'red'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.stripeService.remove_payment_card(token).subscribe((res) => {
          Swal.fire('Félicitation', "La carte a été supprimée avec succès", 'success');
          this.get_my_payments();
          //this.redirectTo("/");
        }, err => {
          if (err.error.error.message == "subscription.auto.renewal") {
            Swal.fire({
              title: "Impossible de supprimer cette carte",
              html: "<p>Vous avez déjà un abonnement actif avec renouvellement automatique.</p><p>Veuillez désactiver le renouvellement automatique avant de procéder à la suppression de la carte.</p>",
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: `Mes abonnements`,
              cancelButtonText: `Annuler`,
              cancelButtonColor: 'red'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/mes-abonnements'], { queryParams: { "return_to": "mes-moyens-paiement" } });
              }
            });
          } else {
            Swal.fire('Erreur', 'Une érreur est survenue', 'error');
          }

        }).add(() => {
          this.spinner.hide();
        })
      }
    });

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
      this.router.navigate([uri]));
  }

  saveCard() {
    this.spinner.show();
    this.stripeService.create_payment_intent_card().subscribe((res: any) => {
      this.stripe.handleCardSetup(
        res.client_secret,
        this.cardElement,
        {
          payment_method_data: {
            billing_details: {
              name: this.authService.currentUserValue.profile.full_name
            }
          }
        }
      ).then((result: any) => {
        if (result.error) {
          this.spinner.hide();
          let errorMessage = result.error;
          let messageText = "";
          switch (errorMessage.code) {
            case 'incomplete_zip':
              messageText = "Votre code postal est incomplet.";
              break;
            case 'incomplete_cvc':
              messageText = "Le code de sécurité de votre carte est incomplet.";
              break;
            case 'incomplete_expiry':
              messageText = "La date d'expiration de votre carte est incomplète.";
              break;
            case "incomplete_number":
              messageText = "Votre numéro de carte est incomplet.";
              break;
            default:
              messageText = "Votre numéro de carte est invalide.";
              break;
          }
          Swal.fire('Erreur', messageText, 'error');


        } else {
          this.stripeService.attach_card(result.setupIntent.payment_method).subscribe(res => {
            this.spinner.hide();
            this.closeFormSaveCard();
            Swal.fire('Félicitation', "La carte a été ajoutée avec succès !", 'success');

            this.activatedRoute.queryParams.subscribe(params => {
              if (params.return_to) {
                this.router.navigate(['/' + params.return_to]);
              } else {
                this.get_my_payments();
              }
            });
          }, err => {
            this.spinner.hide();
            Swal.fire('Erreur', 'Une érreur est survenue', 'error');

          });
        }
      });
    }, (err) => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    });

  }

  async loadStripe() {
    this.spinner.show();
    this.stripe = await loadStripe(environment.stripe_pk);
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card-element');
    this.spinner.hide();
  }

  setStylesBtnAdd() {
    let styles = {
      "display": this.styleBtnAddCard,
    };
    return styles;
  }

  setStylesBtnSaveCard() {
    let styles = {
      "display": this.styleBtnsSaveCard,
    };
    return styles;
  }

  openFormSaveCard() {

    this.styleBtnsSaveCard = "block";
    this.styleBtnAddCard = "none";
    this.loadStripe();
  }

  closeFormSaveCard() {
    this.loadStripe();
    this.styleBtnAddCard = "block";
    this.styleBtnsSaveCard = "none";
  }

}
