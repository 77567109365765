import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesDetailsPageRoutingModule } from './courses-details-page-routing.module';
import { CoursesDetailsPageComponent } from './courses-details-page.component';
import { VjsPlayerComponent } from '../../common/vjs-player/vjs-player.component';
import { TabsModule } from 'ngx-tabset';
import { CourseCardModule } from '../../common/course-card/course-card.module';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { PricingModule } from '../../common/pricing/pricing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { SuggestionCourseCardModule } from '../../common/suggestion-course-card/suggestion-course-card.module';


@NgModule({
  declarations: [
    CoursesDetailsPageComponent,
    VjsPlayerComponent
  ],
  imports: [
    CommonModule,
    CoursesDetailsPageRoutingModule,
    TabsModule,
    //CourseCardModule,
    SuggestionCourseCardModule,
    NgbRatingModule,
    PricingModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ShareButtonModule,
    LazyLoadImageModule
  ]
})
export class CoursesDetailsPageModule { }
