import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Invoice } from 'src/app/models/invoice-model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mes-factures',
  templateUrl: './mes-factures.component.html',
  styleUrls: ['./mes-factures.component.scss']
})
export class MesFacturesComponent implements OnInit {

  nbr_items_in_page: number = 12;
  load_more: boolean = true;
  start_at: string = "";

  
  loading: boolean = true;
  invoices: Array<Invoice> = [];
  subscription_token: string = "";

  constructor(private dateFormatterService:DateFormatterService,private spinner: NgxSpinnerService,private stripeService: StripeService , private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.invoices = [];
    this.get_my_invoinces();
  }

  manage() {
    this.spinner.show();
    this.stripeService.manageBilling().subscribe((res) => {
      window.open(res.url, "_blank");
    }, err => {
      Swal.fire('Erreur', 'Une érreur est survenue', 'error');
    }).add(()=> {
      this.spinner.hide();
    });
  }

  get_my_invoinces() {
    this.activatedRoute.queryParams.subscribe(params => {

      let body: any = { "subscription": ((params.subscription) ? params.subscription : "") , "limit" : this.nbr_items_in_page };
      if(this.start_at != "") {
        body["start_at"] = this.start_at;
      }
      this.stripeService.mes_factures(body).subscribe(res => {
        this.load_more = res.has_more;
        
        res.data.forEach((item: any) => {
          this.invoices.push(new Invoice(Invoice.attributeToJson(item)));
        });
      }, err => {
        this.invoices = [];
        Swal.fire('Erreur', 'Une érreur est survenue', 'error');
      }).add(() => {
        this.loading = false;
      });
    });
    
  }

  date_format(date: any) {
    return this.dateFormatterService.showDateHour(date)
  }

  timestamp_to_date_format(timestamp: any) {
    let  date = new Date(timestamp * 1000);
    return this.dateFormatterService.showDateHour(date)
  }

  get_more() {
    this.start_at = this.invoices[this.invoices.length-1].id!;
    this.get_my_invoinces();
  }
  

}
