import { environment } from 'src/environments/environment';
import { AwsService } from '../services/aws/aws.service';

export class Partner {
    id?: number;
    name?: string;
    logo?: any;
    thumbnail: string = "";
    website: string = "#";

    constructor(data: Partial<Partner>) {
        Object.assign(this, data);
    }

    static attributeToJson(item: any) {
        let obj = item.attributes;
        obj["id"] = item.id;
        obj["logo"] = item.attributes.logo.data.attributes;
        obj["thumbnail"] = AwsService.generate_link(obj["logo"].url);
        return obj;

    }


}
