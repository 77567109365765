<div class="d-lg-flex flex-md-equal w-100 mb-5 ps-md-3 justify-content-evenly">
    <div class="order-last me-md-3 py-3 px-3 px-md-5 text-center overflow-hidden align-content-center">
        <img width="500" class=" ps-2" src="assets/images/mockup-mobile.webp" alt="Image">
    </div>
    <div class="order-first align-content-center me-md-3 py-3 px-3 px-md-5 text-center overflow-hidden">
        <div class="my-3 py-3 text-start">
            <div class="h1">
                Lenidit Mobile
            </div>
            <h2>
                Accédez de manière simple et rapide
            </h2>
            <p>
                à votre compte partout ou vous êtes, depuis votre Smartphone ou votre tablette.<br>
                En plus de cela, vous pouvez désormais télécharger vos vidéos <br>
                préférées pour les consulter sur votre téléphone <br>
                même en l'absence de connexion internet
            </p>
            <div class="">
                <a href="#"><img width="150" class="download-app" src="assets/images/google-play.svg" alt="Image"></a>
                <a href="#"><img width="150" class="download-app ps-2" src="assets/images/appstore.svg" alt="Image"></a>
            </div>

        </div>
    </div>

</div>