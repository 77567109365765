<!-- Page Banner -->
<div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-content">
            <h2>Contactez-nous</h2>
            <ul class="pages-link">
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Contactez-nous</li>
            </ul>
        </div>
    </div>
</div>

<!-- Contact Info -->
<div class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="contact-info-content">
                    <ul>
                        <li>
                            <i class="ri-phone-fill"></i>
                            <h3>Contact</h3>
                            <a href="tel:+1(135)-1984-2020">+1(135) 1984 2020</a>
                        </li>
                        <li>
                            <i class="ri-mail-fill"></i>
                            <h3>Email</h3>
                            <a href="mailto:aimad.tahi@gmail.com">aimad.tahi@gmail.com</a>
                            <a href="mailto:info@edon.com">info@edon.com</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="contact-form main-contact-form">
                    <h2>Prêt à commencer ?</h2>
                    <form id="contactForm" [formGroup]="FormContact" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Nom et prénom :</label>
                                    <input [ngClass]="{'border-danger':contact.full_name.errors && submitFormContact}"
                                        type="text" formControlName="full_name" id="full_name" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Adresse email :</label>
                                    <input [ngClass]="{'border-danger':contact.email.errors && submitFormContact}"
                                        type="email" formControlName="email" id="email" class="form-control" EmailValidator>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Numéro téléphone :</label>
                                    <input [ngClass]="{'border-danger':contact.phone.errors && submitFormContact}"
                                        type="text" formControlName="phone" id="phone" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Sujet :</label>
                                    <input [ngClass]="{'border-danger':contact.subject.errors && submitFormContact}"
                                        type="text" formControlName="subject" id="subject" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Message :</label>
                                    <textarea [ngClass]="{'border-danger':contact.message.errors && submitFormContact}"
                                        formControlName="message" class="form-control" id="message" cols="30"
                                        rows="6"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Envoyer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
