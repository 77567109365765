<div class="container d-flex align-items-center justify-content-center my-5">
    <div class="pt-4 px-4 text-center border col-lg-5 bg-white rounded-4 shadow container-login">
        <a class="navbar-brand" routerLink="/"><img class="mb-4" src="assets/images/logo.png" alt="logo"></a>
        <h2 class="text-body-emphasis">Connexion</h2>
        <p class="col-lg-8 text-muted custom-subtitle-login">
            Se connecter avec
        </p>
        <div class="d-inline-flex gap-2 mb-3">
            <button class="btn px-5 social-btn" type="button" (click)="loginWithFB()">
                <img width="30"
                    src="assets/images/facebook.webp"
                    alt="facebook">
            </button>
            <button class="btn px-5 social-btn" type="button" (click)="loginWithGoogle()">
                <img width="30"
                    src="assets/images/google.webp"
                    alt="mail">
            </button>
        </div>
        <div class="my-3">
            <h4 class="lined-text" style="color: #6a6f73;"><span>OU</span></h4>
        </div>
        <form class="text-start" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email *</label>
                <input class="form-control" type="text" formControlName="identifier">
            </div>
            <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Mot de passe *</label>
                <input class="form-control" type="password" formControlName="password">
            </div>
            <div class="mb-3 text-end">
                <span class="pointer mb-3" (click)="openModal()">Mot de passe oublié ?</span>
            </div>
            <button type="submit" class="btn btn-primary w-100 mb-3">Se connecter</button>
            <p class="text-center">Vous n'avez pas encore de compte ? <a
                    routerLink="/register" [queryParams]="(return_url != '') ? { return: return_url } : {}"><strong>S'inscrire</strong></a></p>
        </form>
        <p class="text-copyright">© 2024 <strong>LENID IT</strong><br>TOUS DROITS SONT RÉSERVÉS</p>
    </div>

</div>


<!-- reset password Modal -->
<ng-template #modalRestPassword let-modal>
    <form class="user-form" [formGroup]="forgetPasswordForm" (ngSubmit)="resetPassword()"
        style="background-color: white;">
        <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLabel">Mot de passe oublié ?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
            <input (keyup.enter)="resetPassword()" [ngClass]="{'border-danger':f2.email.errors && submitForgotPassword}"
                type="email" class="form-control" formControlName="email" placeholder="Email" EmailValidator>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-primary">Envoyer</button>
        </div>
    </form>
</ng-template>