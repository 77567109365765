import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SwitchSubscriptionComponent } from './switch-subscription.component';
import { AuthGuard } from 'src/app/_helpers/auth.guard';

const routes: Routes = [
  {
    path: '', component: SwitchSubscriptionComponent,
    data: {
      title: "Mettre à niveau l'abonnement"
    }, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SwitchSubscriptionRoutingModule { }
