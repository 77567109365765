import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LearningService {

  entity: string = "/learnings";

  constructor(private http: HttpClient, private authService: AuthService) { }

  get(formation_id: string): Observable<any> {
    return this.http.get(environment.api + this.entity +"?formation="+ formation_id, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token } });
  }

  create(chapter_id: string, duration: any,type_video: string): Observable<any> {

    let values = { chapter: chapter_id , duration: duration , type_video: type_video};
    return this.http.post(environment.api + this.entity+"?populate=chapter" , { data: values }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token } });
  }

  update(id: string, duration: any): Observable<any> {
    let values = { duration: duration };
    return this.http.put(environment.api + this.entity+"/"+id+"?populate=chapter" , { data: values }, { headers: { Authorization: 'Bearer ' + this.authService.currentUserValue.token } });
  }
  
}
